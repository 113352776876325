/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
NatalData Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {useUser}                from './UserContext';
import {
    useStateEphemeral,
    useCancelToken,
    useCanQuery
}                               from 'hooks';

// The base API url
const BASE_API_URL = '/api/user/natal';

// The Context Object
export const NatalDataContext = React.createContext(undefined);

// Voltage Provider
export const NatalDataProvider = ({children}) => {
    const {allowQueryForUser}                   = useCanQuery();
    const {
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken}                         = useCancelToken();
    const {isAuthenticated,isAdmin}             = useUser();

    // Messages
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageWarning]                      = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);

    // Data
    const [data,        setData]                = React.useState([]);
    const [isRetrieved, setRetrieved]           = React.useState(false);
    const [queried,     setQueried]             = React.useState(undefined);
    const [loading,     setLoading]             = React.useState(false);
    const [quantity,    setQuantity]            = React.useState(0);

    const clear                                 = React.useCallback(() => {
        setData([]); 
        setQuantity(0);
        setQueried(undefined);
    },[]);

    // Get the data from the server
    const apiGet = React.useCallback( () => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return; //reject(new Error('unauthorized'));
        setLoading(true);
        axios.get(BASE_API_URL, { cancelToken })
            .then(({data,totalQuantity}) => [data,totalQuantity])
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setQueried(moment())
                setRetrieved(true);
                setLoading(false);
            })
    }),[allowQueryForUser, axios, cancelToken]);

    // Create new natal data record
    const apiPost = React.useCallback( (data = {}) => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'));
        setLoading(true);
        axios.post(BASE_API_URL, data, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setLoading(false);
            })
    }), [allowQueryForUser, axios, cancelToken]);

   // Update Natal Data by Id
   const apiPostById = React.useCallback( (docOrId, data = {}) => new Promise((resolve,reject) => {
        const id = docOrId?.id || docOrId;
        if(!allowQueryForUser || !id)
            return reject(new Error('unauthorized'));
        setLoading(true);
        axios.post(`${BASE_API_URL}/${id}`, data, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setLoading(false);
            })
    }),[allowQueryForUser, axios, cancelToken])

    // Delete Natal Data by Id
    const apiDeleteById = React.useCallback( (docOrId) => new Promise((resolve,reject) => {
        const id = docOrId?.id || docOrId;
        if(!allowQueryForUser || !id)
            return reject(new Error('unauthorized'));
        setLoading(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setLoading(false);
            })
    }),[allowQueryForUser, axios, cancelToken])

    // Get collection of natal data records on behalf of another user
    const apiGetForUserId = React.useCallback( (userOrId) => new Promise((resolve,reject) => {
        const id = userOrId?._id || userOrId;
        if(!isAuthenticated || !isAdmin || !id)
            throw new Error('Unauthorised');
        axios.get(`${BASE_API_URL}?asAdmin=true&userId=${id}`, { cancelToken })
            .then(({data,totalQuantity}) => [data,totalQuantity])
            .then(resolve)
            .catch(reject)
    }),[axios, cancelToken, isAdmin, isAuthenticated]);

    // Create new natal data record on behalf of another user
    const apiPostForUserId = React.useCallback( (userOrId, data = {}) => new Promise((resolve,reject) => {
        const id = userOrId?._id || userOrId;
        if(!isAuthenticated || !isAdmin || !id)
            throw new Error('Unauthorised');
        axios.post(`${BASE_API_URL}?asAdmin=true&userId=${id}`, data, { cancelToken })
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
    }), [axios, cancelToken, isAdmin, isAuthenticated]);

    // Update Natal Data by Id
   const apiPostByIdForUserId = React.useCallback( (userOrId, docOrId, data = {}) => new Promise((resolve,reject) => {
        const uid   = userOrId?._id || userOrId;
        const id    = docOrId?.id || docOrId;
        if(!isAuthenticated || !isAdmin || !uid || !id)
            throw new Error('Unauthorised');
        axios.post(`${BASE_API_URL}/${id}?asAdmin=true&userId=${uid}`, data, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
    }),[axios, cancelToken, isAdmin, isAuthenticated])

    // Refresh data from server
    const refresh = React.useCallback( (reset = false) => {
        if(reset) clear();
        apiGet()
            .then(([data,totalQuantity]) => {
                setMessageSuccess("Queried natal data");
                setData(data);
                setQuantity(totalQuantity);
            })
            .catch(err => {
                setMessageError(err?.message);
                clear();
            })

    },[clear, apiGet, setMessageError, setMessageSuccess]) 

    // Refresh
    React.useEffect(refresh, [refresh]);

    // Refresh every X ms
    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(refresh, 15 * 60 * 1000); // 15  minutes
        return () => {
            clearInterval(intervalRef.current);
        }
    },[refresh]) 

    // Create new natal data record
    const createNatalData = React.useCallback( ({lat, lng, address, gender, birthDateTime, unknownTime, localTime, description, photo, isUser, roddenRating}) => new Promise((resolve,reject) => {
        apiPost({lat, lng, address, gender, birthDateTime, unknownTime, localTime, description, photo, isUser, roddenRating}, {cancelToken})
            .then(data => {
                setMessageSuccess('Natal data created');
                return data;
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
    }), [cancelToken, apiPost, setMessageError, setMessageSuccess]);

    // Update Natal Data by Id
    const updateNatalData = React.useCallback( ({id, lat, lng, address, gender, birthDateTime, unknownTime, localTime, description, photo, isUser, roddenRating}) => new Promise((resolve,reject) => {
        apiPostById(id, {lat, lng, address, gender, birthDateTime, unknownTime, localTime, description, photo, isUser, roddenRating})
            .then(data => {
                setMessageSuccess("Natal data updated");
                return data;
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })   
            
    }),[apiPostById, setMessageError, setMessageSuccess])

    // Delete Natal Data by Id
    const deleteNatalData = React.useCallback( ({id}) => new Promise((resolve,reject) => {
        apiDeleteById(id)
            .then(() => {
                setMessageSuccess("Natal data deleted")
                return;
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
    }),[apiDeleteById, setMessageError, setMessageSuccess]);

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(socket && allowQueryForUser){
            socket.on('refresh_natal_data', refresh)
            return () => {
                socket.off('refresh_natal_data', refresh);
            }
        }
    
    },[allowQueryForUser, refresh, socket]); 

    const hasData   = React.useMemo(() => quantity > 0, [quantity]);

    // Context values
    const value = React.useMemo(() => ({
        messageSuccess,
        messageWarning,
        messageError,
        data,
        natalData   : data,
        quantity, 
        hasData,
        refresh,
        createNatalData,
        deleteNatalData,
        updateNatalData,
        queried,
        loading,
        isRetrieved,
        apiGetForUserId,
        apiPostForUserId,
        apiPostByIdForUserId
    }), [
        createNatalData, data, deleteNatalData, 
        hasData, isRetrieved, loading, 
        messageError, messageSuccess, messageWarning, 
        quantity, queried, 
        refresh, updateNatalData,
        apiGetForUserId, apiPostForUserId, apiPostByIdForUserId
    ]);

    return (
        <NatalDataContext.Provider value={value}>
            {children}
        </NatalDataContext.Provider>
    )
}

export const NatalDataConsumer =  ({children}) => {
    return (
        <NatalDataContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('NatalDataConsumer must be used within NatalDataProvider');
                }
                return children(context)
            }}
        </NatalDataContext.Consumer>
    )
}

// useVoltage Hook
export const useNatalData = () => {
    const context = React.useContext(NatalDataContext);
    if(context === undefined)
        throw new Error('useNatalData must be used within NatalDataProvider');
    return context;
}