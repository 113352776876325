/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Current Time Hook
********************************************************************************************
Hook that returns an updating time (moment) object held as a state variable

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th December 2022

*******************************************************************************************/
import React    from 'react';
import moment   from 'moment';

/**
 * Custom hook to get the current time at a specified interval.
 * 
 * @param {number} periodInitial - The interval in milliseconds between each update. Default is 1000 ms.
 * @param {boolean} asMoment - Flag to return time as a Moment.js object (true) or a native JavaScript Date (false). Default is true.
 * 
 * @returns {Object} The current time (`now`), a flag indicating if the clock is running (`running`), 
 *                   and actions to control the clock (`start`, `stop`), along with the `period` value and `setPeriod` method.
 */
export const useClock = (periodInitial = 1000, asMoment = true) => {

    // Inline validation
    const [period, setPeriod] = React.useState(checkValidPeriod(periodInitial)); 

    // Current time state
    const [now, setNow] = React.useState(getCurrentTime(asMoment));

    // Current running state
    const [running, setRunning] = React.useState(true);

    // Start the clock
    const start = React.useCallback(() => setRunning(true), []);

    // Stop the clock
    const stop = React.useCallback(() => setRunning(false), []);

    // Method to update period with validation
    const setPeriodRef = React.useRef( newPeriod => setPeriod(checkValidPeriod(newPeriod)));

    // Handle changes to periodInitial
    React.useEffect(() => {
        setPeriodRef.current(periodInitial);
    }, [periodInitial])

    // Update the current time when `asMoment` changes
    React.useEffect(() => {
        setNow(
            prev => {
                const ex = moment(prev);
                return asMoment 
                    ? ex 
                    : ex.toDate() 
            });
    }, [asMoment]);

    // Set interval and update the current time
    const intervalRef = React.useRef(null);
    React.useEffect(() => {

        clearInterval(intervalRef.current); // Always clear

        if (!running) {
            intervalRef.current = null;
            return;
        }

        // Start interval for updating current time
        intervalRef.current = setInterval(() => {
            setNow(getCurrentTime(asMoment));
        }, period);

        // Cleanup on unmount or dependency change
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [period, running, asMoment]);
    
    // Return Current Time
    return {
        now,
        period,
        running,
        start,
        stop,
        setPeriod : setPeriodRef.current
    };
};

/**
 * Returns the current time based on the `asMoment` flag.
 * If true, returns a Moment.js object, otherwise returns a native JavaScript Date object.
 * 
 * @param {boolean} asMoment - Flag to return time as a Moment.js object (true) or a native JavaScript Date (false).
 * @returns {Date|moment.Moment} The current time.
 */
function getCurrentTime(asMoment){
    return asMoment ? moment() : new Date();
}

/**
 * Checks if the given period is a valid positive number.
 * 
 * @param {number} period - The interval period to be validated.
 * @throws {Error} If the period is not a positive numeric value.
 * @returns {number} The valid period if it passes validation.
 */
function checkValidPeriod(period) {
    if (isNaN(period) || period <= 0) {
        throw new Error('period must be a positive numeric value');
    }
    return period; // Return the valid period
}


export default useClock;