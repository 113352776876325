/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Houses
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import {isEqual,isEmpty,pick,uniqWith}  from 'lodash';
import {titleCase}                      from 'title-case';
import formatcoords                     from 'formatcoords';
import moment                           from 'moment';
import { 
    useTheme, 
    Checkbox, 
    Avatar, 
    Grid, 
    Box, 
    MenuItem, 
    FormHelperText,
    ListSubheader,
    IconButton,
    Tooltip,
    lighten,
    darken
}                                       from '@mui/material';
import PersonIcon                       from '@mui/icons-material/Person';
import EditIcon                         from '@mui/icons-material/Edit';
import {
    Form,
    GoogleMapsStatic,
    CreateNatalData,
    UpdateNatalData,
    Thumbnail,
    JSONViewer,
    RoddenRatingStamp
}                                       from 'components';
import {
    FabAdd,
}                                       from 'components/fabs';
import {
    useNatalData,
    useLocale,
    useUser,
}                                       from 'contexts';
import {
    useImageCDN
}                                       from 'hooks';
import { 
    TextField,
    Select,
    Autocomplete,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {FormSpy}                        from 'react-final-form';
import { withTranslation }              from 'components/hoc';

const NATAL_FIELDS_REQUIRED = ['_id', 'lat', 'lng', 'address', 'birthDateTime', 'unknownTime', 'localTime', 'roddenRating'];
const NATAL_FIELDS_OPTIONAL = ['description','gender','isUser','photo','photoGetSignedUrl']
const obj                   = {};
const noop                  = () => {};

const isNatalData = (x) => {
    if(typeof x !== 'object') return false;
    return NATAL_FIELDS_REQUIRED.every(item => x.hasOwnProperty(item));
}

const UserProfileIcon = () => (
    <PersonIcon 
        sx={{
            color       : theme => theme.palette.secondary.main,
            fontSize    : '0.65rem'
        }}
    />
)

const Warnings = ({name, values, warnings = obj}) => {
    const warning = name && values[name] ? warnings[values[name]] : undefined;
    if(!warning) 
        return null;
    return (
        <FormHelperText error>
            {warning}
        </FormHelperText>
    )
}

const isEmptyValue = (value) => value === undefined || value === null || value === '';

const isObject = value => typeof value === "object" && value !== null;

const enforceObject = (value, defaultValue = {}) => isObject(value) ? value : defaultValue;

const DynamicNatalField = withTranslation(({
    t : tBase,
    userId : userIdIn,
    form,
    disabled,
    values, 
    field,
    type,
    name
}) => {
    
    const t                                         = React.useCallback((key) => tBase(`components.forms.deliveryUserInputForm.${key}`), [tBase]);
    const theme                                     = useTheme();
    const {
        userId : userIdAuth, 
        isAuthenticated, 
        isAdmin
    }                                               = useUser();
    const {
        natalData   : NatalDataOrigA, 
        quantity    : natalDataQuantityA,
        apiGetForUserId
    }                                               = useNatalData();
    const [natalData, setNatalData]                 = React.useState([]);

    const [natalDataCounter,  setNatalDataCounter]  = React.useState(0);
    const [natalDataOrig,     setNatalDataOrig]     = React.useState([]);
    const [natalDataQuantity, setNatalDataQuantity] = React.useState(0);

    const [isOpen,    setIsOpen]                    = React.useState(false);
    const [isFocused, setIsFocused]                 = React.useState(false);
    const [isHovered, setIsHovered]                 = React.useState(false);
    const [isTouched, setIsTouched]                 = React.useState(false);

    const [createOpen, setCreateOpen]               = React.useState(false);

    // Resolve the external userId
    const userId = React.useMemo(() => (
        (isAuthenticated && isAdmin && userIdIn && userIdIn !== userIdAuth) 
            ? userIdIn 
            : undefined
    ), [isAdmin, isAuthenticated, userIdIn, userIdAuth]);

    // Either use the Authenticated Users Natal Data, or Retrieve for 3rd Party
    React.useEffect(() => {
        if(userId){
            apiGetForUserId(userId)
                .then(([data,totalQuantity]) => {
                    setNatalDataOrig(data);
                    setNatalDataQuantity(totalQuantity)
                }).catch(err => {
                    console.error(err)
                })
        }else{
            setNatalDataOrig(NatalDataOrigA);
            setNatalDataQuantity(natalDataQuantityA)
        }
    },[NatalDataOrigA, apiGetForUserId, natalDataQuantityA, userId, natalDataCounter])

    React.useEffect(() => {
        if(type === 'natal'){
            let NATAL_FIELDS = [
                ...NATAL_FIELDS_REQUIRED,
                ...NATAL_FIELDS_OPTIONAL
            ];
            let existingRecord = natalDataOrig.find(nd => nd._id === values[name]?._id);
            if (existingRecord && !isEqual(existingRecord, values[name])) {
                form.change(name, existingRecord);  // Update form if record exists
            }
            let data = [
                existingRecord || values[name],  // Prioritize existing record if found
                ...natalDataOrig.filter(nd => nd._id !== values[name]?._id)  // Avoid duplicates
            ]
                .filter(Boolean)                            // Remove null/undefined values
                .filter(isNatalData)                        // Ensure valid data
                .map(nd => pick(nd, NATAL_FIELDS))          // Pick necessary fields
            
            // Sort: Selected item first, then by birthDateTime
            data.sort((a, b) => {
                if (a._id === values[name]?._id) return -1;  // Selected item first
                if (b._id === values[name]?._id) return +1;
                return moment(a.birthDateTime) - moment(b.birthDateTime);
            });

            // Select unique only
            data = uniqWith(data, isEqual);                 

            // Now set the state variable
            setNatalData(data)
        }else{
            // Not needed
            setNatalData([]);
        }
    },[natalDataOrig, type, name, values, form])

    const {formatDate, formatTime}  = useLocale();

    let label = t(name)

    return (
        <Box display="flex">
            <Box flexGrow={1}>  
                <Autocomplete
                    name                    = {name} 
                    label                   = {natalDataQuantity ? (label + ` (${natalDataQuantity})`) : label}
                    disabled                = {disabled}
                    showError               = {showError}
                    value                   = { values[name] || null }
                    options                 = {natalData}
                    disableCloseOnSelect    = {false}
                    autoSelect              = {true}
                    freeSolo                = {false}
                    // blurOnSelect            = {true}
                    isOptionEqualToValue    = {(option,value) => isEqual(option, value)}
                    getOptionValue          = {option => option}
                    getOptionLabel          = {option => {
                        if (isEmpty(option)) {
                            return t('pleaseSelectNatalRecord');
                        }

                        // Build Timestamp
                        const timestampPart = [
                            formatDate(moment.utc(option?.birthDateTime)),
                            !option?.unknownTime && 
                            formatTime(moment.utc(option.birthDateTime)),
                            option?.localTime ? "LMT" : "GMT",
                        ]
                            .filter(Boolean)
                            .join(' ') || '';

                        // Build Geolocation
                        const geolocationPart = (
                            (option?.lat || option?.lat === 0) && (option?.lng || option?.lng === 0)
                                ? formatcoords(option.lat, option.lng).format({ decimalPlaces: 1 })
                                : ''
                        );

                        // Build RoddenRating 
                        const roddenRatingPart = (
                            option?.roddenRating 
                                ? `RR-${option?.roddenRating}` 
                                : ''
                        )
                    
                        // Build label components
                        const parts = [
                            option?.description,
                            roddenRatingPart,
                            timestampPart,
                            geolocationPart,
                            option?.address
                        ];
                    
                        // Filter out falsy values and join the remaining parts with `; `
                        return parts.filter(Boolean).join('; ') || '';
                    }}
                    renderInput = {(params) => {
                        return (
                            <TextField
                                {...params} 
                                disabled        = {disabled}
                                label           = {natalDataQuantity ? `${label} (${natalDataQuantity})` : label}
                                name            = {name}
                                InputLabelProps = {{ shrink : true }}
                                InputProps      = {{
                                    ...params.InputProps,
                                    readOnly : true,
                                    onKeyDown: (e) => {
                                        e.preventDefault()// Blocks keyboard input
                                        if(!isObject( values[name]))
                                            setCreateOpen(true);
                                    }, 
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {
                                                // TODOD
                                                values[name] && isObject(values[name]) && (isOpen || isFocused || isHovered || isTouched) && (
                                                    <UpdateNatalData 
                                                        userId          = {userId}
                                                        formData        = { enforceObject(values[name]) }
                                                        disabled        = {disabled}
                                                        onOpenChange    = {setIsOpen} // Guardrail to force Open state once Open regardless of focussed/hovered/touched events
                                                        onChange        = {(data) => {
                                                            setNatalDataCounter(prev => prev + 1)
                                                        }}
                                                        component       = {IconButton} 
                                                        edge            = "end"
                                                        size            = "small"
                                                        aria-label      = "edit"
                                                    >
                                                        <Tooltip title={tBase('common.edit')} arrow>
                                                            <EditIcon sx={{fontSize:16}}/>
                                                        </Tooltip>
                                                    </UpdateNatalData>
                                                )
                                            }
                                        </>
                                    ),
                                }}
                                
                                onFocus         = {() => setIsFocused(true) }
                                onBlur          = {() => setIsFocused(false)}
                                onMouseEnter    = {() => setIsHovered(true) }
                                onMouseLeave    = {() => setIsHovered(false)}
                                onTouchStart    = {() => setIsTouched(true) }
                                onTouchEnd      = {() => {
                                    setTimeout(() => setIsTouched(false), 200); // slight delay
                                }}
                            />
                        )
                    }}
                    
                    renderOption = {(option, optionData,...other) => {
                        // console.log(optionData);
                        const checked = optionData._id && optionData._id === values[name]?._id;
                        return (
                            <MenuItem 
                                key     = {option.key} 
                                onClick = {(e) => {
                                    option.onClick(e);
                                    form.change(name, optionData);
                                }}
                                sx = {{
                                    ...(checked && {
                                        background : (theme.palette.mode === 'light' 
                                            ? lighten(theme.palette.primary.light,  0.5) 
                                            : darken( theme.palette.primary.light,  0.5)
                                        ),
                                        '&:hover': {
                                            background : (theme.palette.mode === 'light' 
                                                ? lighten(theme.palette.primary.main,   0.475) 
                                                : darken( theme.palette.primary.main,   0.475)
                                            )
                                        }
                                    })
                                }}
                            >
                                <Box display="flex" sx={{fontSize:'0.75em',width:'100%'}} ml={-1}>
                                    <Box style={{margin:'auto'}}>
                                        <Checkbox 
                                            checked = {checked} 
                                            style   = {{padding:theme.spacing(1),fontSize:16}}
                                        />
                                    </Box>
                                    <Box style={{width:50,padding:2}}>
                                        <Avatar style = {{width:50,height:50}} src={optionData?.photoGetSignedUrl} alt=""/>
                                    </Box>
                                    <Box ml={1} style={{width:50,padding:2}}>
                                        <GoogleMapsStatic zoom={15} lat={optionData?.lat} lng={optionData?.lng} width={100} height={100}/>
                                    </Box>
                                    <Box ml={1} sx={{overflow:'hidden'}} flexGrow={1}>
                                        {   (optionData?.description || optionData?.isUser) &&
                                            <Box display="flex" width="100%">
                                                {
                                                    optionData?.description && 
                                                    <Box>
                                                        {optionData?.description} {optionData?.isUser ? ` (${t('you')})` : null}
                                                    </Box>
                                                }
                                                {
                                                    !optionData?.description && optionData?.isUser &&
                                                    <Box>
                                                        { t('yourNatalRecord') }
                                                    </Box>
                                                }
                                                <Box flexGrow={1}>
                                                    {optionData?.isUser ? <UserProfileIcon/> : null}
                                                </Box>
                                                <Box flexGrow={1}/>
                                                {
                                                    optionData?.roddenRating && 
                                                    <Box ml={1} sx={{width:25, textAlign:'center'}}>
                                                        <RoddenRatingStamp 
                                                            code    = {optionData?.roddenRating} 
                                                            sx      = {{
                                                                minWidth        : 25,
                                                                border          : '1px solid black',
                                                                fontSize        : '0.5rem',
                                                                padding         : '1px',
                                                                borderRadius    : '2px',
                                                            }}
                                                        />
                                                    </Box>
                                                    
                                                }
                                            </Box>
                                        }
                                        <div>
                                            <strong>{formatDate(moment.utc(optionData.birthDateTime))} {optionData?.unknownTime ? null : formatTime(moment.utc(optionData.birthDateTime))} {optionData?.localTime ? "LMT" : "GMT"}</strong>
                                        </div>
                                        {
                                            optionData?.lat && optionData?.lng &&
                                            <div>
                                                {formatcoords(optionData?.lat,optionData?.lng).format({decimalPlaces:1})}
                                            </div>
                                        }
                                        <div style={{wordBreak: "break-word",maxWidth:200}}>
                                            {optionData?.address}
                                        </div>
                                    </Box>
                                </Box>
                            </MenuItem>
                        )
                    }}
                    multiple={false}
                />
                <FormHelperText>
                    {field?.helperText || field?.description}
                </FormHelperText>
                <Warnings name={name} values = {values} warnings = {field?.warnings}/>
                <FormSpy
                    subscription = {{ values: true }}
                    onChange     = {({values}) => {
                        form.change(name, values[name]);
                    }}
                />
            </Box>
            <Box flexShrink={1} pt={1} mr={-2}>
                <CreateNatalData 
                    userId              = {userId}
                    disabled            = {disabled}
                    component           = {FabAdd} 
                    tooltip             = {t('createNatalData')} 
                    pulse               = {!Boolean(natalData.length)} 
                    color               = "primary"
                    open                = {createOpen}
                    onOpenChange        = {setCreateOpen}
                    onChange            = {(values) => {
                        if(isObject(values))
                            form.change(name, values);
                    }}
                    natalDataFormProps  = {{
                        usePhoto : false,
                    }}
                />
            </Box>
        </Box>
    );
})

const DynamicEnumField = withTranslation(({
    t : tBase,
    userId,
    form,
    disabled,
    values, 
    field,
    type,
    name
}) => {
    const convert   = useImageCDN();
    const t         = React.useCallback((key) => tBase(`components.forms.deliveryUserInputForm.${key}`), [tBase]);
    let label       = t(name)

    if(type !== 'enum' || !field?.options) 
        return null;

    const hasPreview    = (field?.options || []).map(({image}) => image).some(Boolean);
    const quantity      = (field?.options || []).length;

    return (
        <React.Fragment>
            <Select 
                disabled        = { disabled }
                name            = { name } 
                label           = { quantity ? `${label} (${quantity})` : label }
                helperText      = { field?.helperText || field?.description } 
                showError       = { showError }
                inputLabelProps = {{ 
                    shrink : true 
                }}
            >
                {
                    hasPreview &&
                    <ListSubheader sx={{fontWeight:800}}>
                        {t('clickThumbnailForPreview')}
                    </ListSubheader>
                }
                {
                    (field?.options || []).map(({value, label : optionLabel, image : preview}, id) => {
                        const   SMALL_SIZE          = 50, 
                                LARGE_SIZE          = 500;
                        const [thumbnail,highres]   = [ SMALL_SIZE, LARGE_SIZE ].map(width => convert( preview, { operation : 'width', width }));
                        const title                 = titleCase(optionLabel || value)
                        return (
                            <MenuItem key={id} value={value}>
                                <Box display="flex" sx = {{ width:'100%' }}>
                                    <Box flexGrow={1} sx = {{ my:'auto' }}>
                                        { title }
                                    </Box>
                                    {
                                        preview &&
                                        <Box 
                                            onClick = { 
                                                e => {
                                                    e.stopPropagation(); 
                                                    e.preventDefault();
                                                }
                                            }
                                        >
                                            <Thumbnail 
                                                title           = { t('preview') } // 
                                                thumbnail       = { thumbnail }  
                                                src             = { highres }  
                                                thumbnailSize   = { 25 }
                                            >
                                                {t('previewSampleDescription')}
                                            </Thumbnail>
                                        </Box>
                                    }
                                </Box>
                            </MenuItem>
                        )
                    })
                }
            </Select>
            <Warnings name={name} values = {values} warnings = {field?.warnings}/>
        </React.Fragment>
    )
});

const DynamicDefaultField = withTranslation(({
    t : tBase,
    userId,
    form,
    disabled,
    values, 
    field,
    type,
    name
}) => {
    const t         = React.useCallback((key) => tBase(`components.forms.deliveryUserInputForm.${key}`), [tBase]);
    let label       = t(name)
    const isEnum    = Boolean(field?.enum && Array.isArray(field?.enum))
    const quantity  = isEnum ? (field?.enum || []).length : 0;
    return (
        <>
            {
                isEnum && 
                <Select 
                    disabled        = { disabled }
                    name            = { name } 
                    label           = { quantity ? `${label} (${quantity})` : label } 
                    helperText      = { field?.helperText || field?.description } 
                    showError       = { showError}
                    inputLabelProps = {{ 
                        shrink : true 
                    }}
                >
                    {
                        field.enum.map((value,id) => (
                            <MenuItem key={id} value={value}>
                                { titleCase(value) }
                            </MenuItem>
                        ))
                    }
                </Select>
            }
            {
                !isEnum &&
                <TextField 
                    disabled    = { disabled }
                    type        = "text"
                    name        = { name } 
                    label       = { label }
                    value       = { values[name] }
                    showError   = { showError }
                    helperText  = { field?.helperText || field?.description } 
                />
            }
            <Warnings name={name} values = {values} warnings = {field?.warnings}/>
        </>
    )
})

const DynamicField = ({userId, form, disabled, values, field, type, name}) => {
    const args = {
        userId      : userId,
        form        : form,
        disabled    : disabled,
        values      : values,
        field       : field,
        type        : type,
        name        : name,
    }
    switch(type){
        case 'natal':   
            return <DynamicNatalField {...args} />
        case 'enum':    
            return <DynamicEnumField {...args} />
        case 'string':
        default:        
            return <DynamicDefaultField {...args} />
    }
};

export const DeliveryUserInputForm = withTranslation(({
    t : tBase,
    userId : userIdDelivery     = undefined,
    formData                    = obj,
    types                       = obj,
    fields                      = obj,
    delivery                    = obj, // Details About the Delivery
    disabled                    = false,
    onSubmit : handleSubmit     = noop,
    onCancel : handleCancel     = noop,
    FormProps                   = obj,
}) => {
    const t         = React.useCallback((key) => tBase(`components.forms.deliveryUserInputForm.${key}`), [tBase]);
    const validate  = React.useCallback( (values) => {

        let errors = {};

        // Required Fields
        Object
            .keys(formData)
            .forEach(key => {
                if(isEmptyValue(values[key]))
                    errors[key] = errors[key] || t('required');
            })

        // Errors
        return errors;

    }, [t, formData]);

    const initialValues = React.useMemo(() => (
        Object
            .entries(formData)
            .reduce((acc,[key,value]) => ({
                ...acc,
                [key] : value || fields.find(x => x.name === key)?.default || undefined
            }), {})
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(fields), formData]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {
                                Object.keys(types).map((key,ix) => {
                                    let field = fields.find(x => x.name === key) || {};
                                    return (
                                        <Grid key={ix} item xs={12}>
                                            <DynamicField 
                                                userId      = {userIdDelivery}
                                                disabled    = {disabled} 
                                                key         = {ix} 
                                                type        = {types[key]} 
                                                field       = {field} 
                                                name        = {key}
                                                form        = {form} 
                                                values      = {values}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        {false && <JSONViewer src={values} />}
                    </form>
                )
            }}
        />
    )
});

export default DeliveryUserInputForm;