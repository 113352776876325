
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useElement Size Hook
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th July 2022

Usage: 

    import {Box}            from '@mui/material';
    import useElementSize   from 'hooks/useElementSize';

    const MyComponent = (props) => {
        const [setRef, {width,height}] = useElementSize();
        return (
            <Box ref={setRef} width="100%">
                Component Dimensions are {width}px x {height}px
            </Box>
        )
    }

*******************************************************************************************/

import React from 'react';

export const useElementSize = ({ width = 0, height = 0} = {}) => {

    alert('PLEASE REPLACE useElementSize w/ useSize Hook')

    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    // const [ref,     setRef]     = React.useState(null)
    const [domNode, setDomNode] = React.useState(null);
    const [size,    setSize]    = React.useState({ width, height})

    // Resize Handler
    const handleResize = React.useCallback(() => {
        setSize({ 
            width   : domNode?.offsetWidth  || 0, 
            height  : domNode?.offsetHeight || 0
        })
    }, [domNode?.offsetWidth, domNode?.offsetHeight])
  
    // Listen to changes
    const timeoutRef = React.useRef(null);
    React.useLayoutEffect(() => {
        clearTimeout(timeoutRef.current);
        if(size.height !== 0 || size.width !== 0)
            handleResize();
        else
            timeoutRef.current = setTimeout(handleResize, 250);
        window.addEventListener('resize', handleResize)
        return () => {
            clearTimeout(timeoutRef.current);
            window.removeEventListener('resize', handleResize)
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domNode?.offsetWidth, domNode?.offsetHeight])

    // Done
    return [setDomNode, size];
}

export default useElementSize;
