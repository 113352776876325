/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use Scroll to Hash
********************************************************************************************
Scrolls to hash, and then redirects to same page without the hash.

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                            from 'react';
import {useTheme}                       from '@mui/material';
import { useLocation, useHistory}       from 'react-router-dom'


export const useScrollToHash = () => {
    const theme                                 = useTheme();
    const history                               = useHistory();
    const {pathname,hash}                       = useLocation()

    const timeoutRefA = React.useRef(null);
    const timeoutRefB = React.useRef(null);
    // https://stackoverflow.com/a/61311926/1834057
    // https://stackoverflow.com/a/49860927/1834057
    React.useEffect(() => {
        
        clearTimeout(timeoutRefA.current);
        clearTimeout(timeoutRefB.current);
        
        if (hash === '')
            return;

        timeoutRefA.current = setTimeout(() => {
            const element = document.getElementById(hash) || document.getElementById((hash || '').replace('#',''));
            let offsetPosition = 0;
            if (element){
                const headerOffset      = theme.spacing(2);
                const elementPosition   = element.getBoundingClientRect().top;
                offsetPosition          = elementPosition - headerOffset;
            }
            window.scrollTo({ top : offsetPosition, behavior : "smooth"});
            timeoutRefB.current = setTimeout(()=>{
                history.push(pathname); // remove hash, scroll to current position
                window.scrollTo({top : window.pageYOffset});
            },1000)
        }, 0);

        return () => {
            clearTimeout(timeoutRefA.current);
            clearTimeout(timeoutRefB.current);
        }

    }, [hash, history, pathname, theme]); // do this on route change
}

export default useScrollToHash;