/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateChip Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th June 2021

*******************************************************************************************/

import React                            from 'react';
import { ColorChip }                    from './ColorChip';
import { useLocale }                    from 'contexts';
import { withTranslation }              from './hoc';

export const DateChip = withTranslation( ({
    t,
    date,
    prefix  = undefined,
    live    = false,
    ...props
}) => {

    const {formatDateTime} = useLocale();

    // Memoised Formatting Function
    const fmt = React.useCallback( momentObject => [prefix, formatDateTime(momentObject)].filter(Boolean).join(' '), [formatDateTime, prefix]);
        
    // State of Bounds
    const [label, setLabel] = React.useState(fmt(date))

    // Update Timestamp
    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        clearInterval(intervalRef.current);
        if(!date && live){
            intervalRef.current = setInterval( () => setLabel( fmt(date) ), 1000);
            return () => {
                clearInterval(intervalRef.current);
            }
        }
        if(!date && !live)  
            setLabel( t('common.now') );
        if(date)            
            setLabel( fmt(date) );
    }, [date, fmt, live, t])

    // Done, Render
    if(!label) return null;

    return (
        <ColorChip label={label} {...props} style = {{ minWidth : 150 }}/>
    )
});

export default DateChip;