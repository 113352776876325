/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CurrentLocation Context
********************************************************************************************

Tries to determine the users current lat/lng from google api

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th June 2021

*******************************************************************************************/
import React                from 'react';
import moment               from 'moment';
import formatcoords         from 'formatcoords';
import { useAxios }         from '../hooks/services/useAxios';
import { useCancelToken }   from 'hooks';

const UPDATE_INTERVAL = 30 * 60 * 1000; // 30 minutes

// The Context Object
export const CurrentLocationContext = React.createContext(undefined);

// Voltage Provider
export const CurrentLocationProvider = ({children}) => {

    const {axios}                                   = useAxios();
    const {cancelToken }                            = useCancelToken();
    const [queried,     setQueried]                 = React.useState(undefined);
    const [valid,       setValid]                   = React.useState(false);
    const [location,    setLocation]                = React.useState({
        latitude    : 0, 
        longitude   : 0,
    });
    const [coordsFormatted, setCoordsFormatted]     = React.useState(undefined);

    const queryLocation = React.useCallback( () => new Promise((resolve,reject) => {
        axios.get('/api/public/geolocate', { cancelToken })
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
    }),[axios, cancelToken])

    const refresh = React.useCallback(() => {
        if(!queried || moment().subtract(30,'minutes').isSameOrAfter(queried)){
            queryLocation()
                .then(({latitude,longitude}) => {
                    setQueried(moment())
                    setLocation({latitude, longitude})
                })
                .catch(err => {
                    //
                })
        }
    },[queried, queryLocation])

    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(refresh, UPDATE_INTERVAL) // every 30 minutes
        return () => {
            clearInterval(intervalRef.current);
        }
    },[refresh]);

    React.useEffect(refresh,[refresh])

    React.useEffect(()=>{
        const   {latitude,longitude}    = location;
        const   validLatitude           = !isNaN(latitude)  && latitude  >= -90  && latitude  <= 90,
                validLongitude          = !isNaN(longitude) && longitude >= -180 && longitude <= 180;
        const   isValid                 = validLatitude && validLongitude;
        setValid(isValid)
        setCoordsFormatted(isValid ? formatcoords(latitude,longitude).format() : undefined);
    },[location])

    // Context values
    const value = React.useMemo(() => ({
        ...location,
        lat     : location.latitude,
        lng     : location.longitude,
        valid,
        queried,
        coordsFormatted
    }), [coordsFormatted, location, queried, valid]);

    return (
        <CurrentLocationContext.Provider value={value}>
            {children}
        </CurrentLocationContext.Provider>
    )
}

export const CurrentLocationConsumer =  ({children}) => {
    return (
        <CurrentLocationContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CurrentLocationConsumer must be used within CurrentLocationProvider');
                }
                return children(context)
            }}
        </CurrentLocationContext.Consumer>
    )
}

// useVoltage Hook
export const useCurrentLocation = () => {
    const context = React.useContext(CurrentLocationContext);
    if(context === undefined)
        throw new Error('useCurrentLocation must be used within CurrentLocationProvider');
    return context;
}