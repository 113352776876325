
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
APIHealth Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th December 2021

*******************************************************************************************/
import React                from 'react';
import { useInternet }      from 'hooks/services';
import { useAxios }         from 'hooks/services';
import { useCancelToken }   from 'hooks';

// The Product Context 
const APIHealthContext              = React.createContext(undefined);
const HEALTH_FREQUENCY_HEALTHY      = 30000;
const HEALTH_FREQUENCY_UNHEALTHY    =  1000;
const BASE_API_URL                  = '/api/public/health';

// APIHealth Provider
const APIHealthProvider = ({children}) => {

    const {axios}                           = useAxios();
    const {cancelToken}                     = useCancelToken();
    const {isOnline}                        = useInternet();
    const [isAPIHealthy,    setAPIHealthy]  = React.useState(false);
    const [checked,         setChecked]     = React.useState(false);

    const performHealthCheck = React.useCallback(() => new Promise((resolve,reject) => {

        // Query Server
        const request = new Promise((resolve, reject) => {
            axios.get(BASE_API_URL, {cancelToken})
                .then(resolve)
                .catch(reject)
        });    

        // Timeout after X ms
        const timeout = new Promise((_,reject) => setTimeout(reject, 30000, 'Request timed out'));
        
        Promise.race([timeout, request])
            .then(() => true)
            .then(resolve)
            .catch( (err) => {
                if(err.isCancel) return reject(err);
                resolve(false);
            })
    }),[axios, cancelToken ])

    // Function to check online status
    const checkAPIHealthy = React.useCallback((source = undefined) => new Promise(resolve => {
        // if(source) console.log(`Performing Health Check, Source: ${source}`)
        performHealthCheck()
            .then(status => {
                setAPIHealthy(status);
                setChecked(true);
                resolve(status);
            })
            .catch(() => {
                setAPIHealthy(false);
                resolve(false)
            })
    }),[performHealthCheck]);

    // Check online status every X seconds
    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        clearInterval(intervalRef.currnet);
        checkAPIHealthy();
        intervalRef.current = setInterval(
            checkAPIHealthy, 
            isAPIHealthy 
                ? HEALTH_FREQUENCY_HEALTHY 
                : HEALTH_FREQUENCY_UNHEALTHY
        );
        return () => {
            clearInterval(intervalRef.current);
        }
    },[checkAPIHealthy, isAPIHealthy, isOnline])

    // Context values
    const value = React.useMemo(() => ({
        checked,
        checkAPIHealthy,
        isAPIHealthy,
        isOnline
    }), [checkAPIHealthy, checked, isAPIHealthy, isOnline]);

    return (
        <APIHealthContext.Provider value={value}>
            {children}
        </APIHealthContext.Provider>
    )
}

// APIHealth Consumer
const APIHealthConsumer = ({children}) => {
    return (
        <APIHealthContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('APIHealthConsumer must be used within APIHealthProvider');
                }
                return children(context)
            }}
        </APIHealthContext.Consumer>
    )
}

// useAPIHealth Hook
const useAPIHealth = () => {
    const context = React.useContext(APIHealthContext);
    if(context === undefined)
        throw new Error('useAPIHealth must be used within APIHealthProvider');
    return context;
}

export {
    APIHealthProvider,
    APIHealthConsumer,
    useAPIHealth
}