/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sabian Symbols Sampler
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th December 2023

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import startCase                from 'lodash/startCase';
import { Link }                 from 'react-router-dom';
import {
    alpha,
    useTheme,
    Box,
    Grid,
    Typography,
    MenuItem, Select, FormControl, InputLabel
}                               from '@mui/material';

import ChangeCircleIcon         from '@mui/icons-material/ChangeCircle';
import { 
    Title,
    DebouncedButton,
    RefreshIcon,
    Thumbnail,
    ImageLinkWithOverlay,
    Glyph,
    ViewProductButtonSabian,
    JSONViewer,
}                               from 'components';
import {
    useNetwork,
    useUser,
}                               from 'contexts';
import { withTranslation }      from './hoc';
import { 
    watermarkOption,
    useImageCDN,
    useGlyphs,
    useCancelToken
}                               from 'hooks';

const SYMBOLS_TYPE  = 'sabian'
const SIZE_MAP      = { small: 20, medium: 50, large: 100 };
const DEFAULT_SIZE  = SIZE_MAP.medium;

export const SingleSymbolPhrase = ({
    phrase, 
    degrees, 
    degreesFormatted30, 
    feature, 
    featureLabel, 
    sign, 
    signLabel
}) => {
    const glyphs            = useGlyphs();
    const featureGlyph      = React.useMemo(() => glyphs[feature],  [glyphs, feature]);
    const sgnGlyph          = React.useMemo(() => glyphs[sign], [glyphs, sign]);
    return (
        <Typography variant="body2">
            <strong>{startCase(SYMBOLS_TYPE)} Symbol #{degrees + 1}</strong> - {degreesFormatted30} {" "}
            {featureLabel}  <span><Glyph src = {featureGlyph} alt = {featureLabel} /></span> in {" "} 
            {signLabel} <span><Glyph src = {sgnGlyph} alt = {signLabel} /></span>
            - <span style={{fontStyle:'italic'}}>{phrase}</span>
        </Typography>
    )
}

export const SingleSymbol =  withTranslation( ({t, 
    symbolStyle = undefined, 
    data : {
        image : {
            _id, 
            file
        } = {}, 
        phrase, 
        degrees, 
        degreesFormatted30, 
        key         : feature, 
        keyLabel    : featureLabel, 
        sign, 
        signLabel
    } = {},
    watermarked = true,
    preview     = false
} = {}) => {
    
    const theme         = useTheme();
    const {isAdmin}     = useUser();
    const convert       = useImageCDN();

    const glyphs        = useGlyphs();
    const featureGlyph  = React.useMemo(() => glyphs[feature],  [glyphs, feature]);
    const sgnGlyph      = React.useMemo(() => glyphs[sign],     [glyphs, sign]);

    const convertImage  = React.useCallback( (width) => (
        convert( file, {  
            operation : 'width', 
            width, 
            options : [
                preview         ? 'blur:5' : '',
                watermarked     ? watermarkOption(width/2, theme.palette.mode === 'dark') : ''
            ].filter(Boolean).join(',')
        })
    ), [convert, file, theme.palette.mode, watermarked, preview]);

    return (

        <Box sx={{position:'relative'}}>
            <Thumbnail 
                title                   = {t('components.example.symbolViewer')}
                thumbnail               = {convertImage(250)}
                src                     = {convertImage(500)}
                download                = { isAdmin ? convert(file, { operation:'cdn' } ) : null }
                dialogProps             = {{ fullScreen : true, maxWidth : "sm" }}
                alt                     = {`${degrees}°, ${featureLabel} in ${signLabel}, ${[symbolStyle,'artwork'].filter(Boolean).join(" ")}`}
                size                    = {500}
                commentsThreadReference = {_id}
                preview                 = { preview }
                render                  = {({handleOpen, thumbnail, alt}) => (
                    <ImageLinkWithOverlay
                        aspectRatio         = "1"
                        photo               = {thumbnail}
                        alt                 = {alt}
                        loading             = "lazy"
                        onClick             = {!preview ? handleOpen : null}
                        name                = {`${degrees}°, ${featureLabel} in ${signLabel}`}
                        overlayEnabled      = {!preview}
                        component           = {preview ? Box : Link}
                        style               = {{cursor : preview ? 'initial' : 'pointer'}}
                        // sx                  = {{cursor:'pointer'}}
                    />
                )}
            >
                <SingleSymbolPhrase {...{phrase, degrees, degreesFormatted30, feature, featureLabel, sign, signLabel}} />
            </Thumbnail>
            {
                !preview && 
                <Box position="absolute" style={{borderBottomRightRadius:8}} sx={{width:'fit-content', px:0.5, top:0, left:0, bgcolor:theme => alpha(theme.palette.background.paper,0.5)}}>
                    <Glyph src = {featureGlyph} alt = {featureLabel} />
                    <Glyph src = {sgnGlyph} alt = {signLabel} />
                </Box>
            }
        </Box>
    )
})

const noop = () => {}

const nonRandomDisabled = (maxNonRandom, index) => (
    typeof maxNonRandom === "number" && !isNaN(maxNonRandom) && maxNonRandom > 0 && index >= maxNonRandom
)

const SymbolSetItem = ({ label, image, greyscale, size = "small"}) => {

    const convert   = useImageCDN();

    const thumbnail = convert(image, {
        operation   : 'width',
        width       : 100,
        options     : `grayscale:${greyscale}`
    });

    const highres = convert(image, {
        operation   : 'width',
        width       : 500,
        options     : `grayscale:${greyscale}`
    });

    const thumbnailSize = (
        typeof size === "number" && size > 0 ? size : SIZE_MAP[size] ?? DEFAULT_SIZE
    );

    return (
        <Box display="flex" alignItems="center">
            <Thumbnail
                title           = {label}
                thumbnail       = {thumbnail}
                src             = {highres}
                thumbnailSize   = {thumbnailSize}
            />
            <Box sx={{ ml: 0.5 }}>
                {label}
            </Box>
        </Box>
    );
};

export const SabianSymbolsSampler =  withTranslation(({
    t,
    data : {
        lat,
        lng,
        birthDateTime,
        unknownTime,
        localTime,
        houseSystem,
        zodiac
    } = {},
    watermarked     = true,
    preview         = false,
    showOrderButton = false,
    random          = true,
    maxNonRandom    = 3,
    renderOverlay   = noop,
}) => {

    const theme                                     = useTheme();
    const {axios,   isNetworkReady}                 = useNetwork();
    const {cancelToken}                             = useCancelToken();
    
    // Query Sabian Symbols
    const [symbolsWorking,  setSymbolsWorking]      = React.useState(false);
    const [symbolsResult,   setSymbolsResult]       = React.useState(undefined);
    const [symbolsSet,      setSymbolsSet]          = React.useState(undefined);
    const currentSymbolsSet                         = React.useRef(undefined);

    const [imageSetsWorking,setImageSetsWorking]    = React.useState(false);
    const [imageSets,       setImageSets]           = React.useState(undefined);
    const [imageSetValue,   setImageSetValue]       = React.useState(undefined);

    const natalArgs                                 = React.useMemo(() => ({
        lat,
        lng,
        birthDateTime : moment.isMoment(birthDateTime) ? birthDateTime.toISOString() : birthDateTime,
        unknownTime,
        localTime,
        houseSystem,
        zodiac
    }), [birthDateTime, houseSystem, lat, lng, localTime, unknownTime, zodiac]);

    const queryString                               = React.useMemo(() => (
        Boolean(natalArgs) && 
        Object.entries(natalArgs).map(([key,value]) => `${key}=${value}`).join('&')
    ),[natalArgs]);

    const queryImageSets                            = React.useCallback(() => {
        setImageSetValue(undefined);
        setImageSets(undefined);
        if(isNetworkReady && axios && !random){

            // Construct the URL
            const url = `/api/public/symbols/imageSets?symbolsType=${SYMBOLS_TYPE}`

            // Query
            setImageSetsWorking(true);
            axios.get(url, { cancelToken })
                .then(({ data }) => data )
                .then(data => {
                    setImageSetValue(data[0].value);
                    setImageSets(data);
                })
                .catch(console.error)
                .finally(() => {
                    setImageSetsWorking(false);
                })
        }
    }, [axios, cancelToken, isNetworkReady, random]) 

    // Query Server
    const querySymbols                              = React.useCallback(() => {
        setSymbolsResult(undefined);
        setSymbolsSet(undefined);
        currentSymbolsSet.current = undefined;

        if(isNetworkReady && axios && queryString && (random || imageSetValue)){

            // Construct the URL
            const url = [
                `/api/public/symbols?symbolsType=${SYMBOLS_TYPE}`,
                queryString,
                !random && imageSetValue 
                    ? `symbolsSet=${imageSetValue}`
                    : undefined,
                (random || !imageSetValue) && currentSymbolsSet.current 
                    ? `excludeSymbolsSet=${currentSymbolsSet.current}` 
                    : undefined
            ]
                .filter(Boolean)
                .join('&')

            // Query
            setSymbolsWorking(true);
            axios.get(url, { cancelToken })
                .then(({ data }) => data )
                .then(({ symbols, result }) => {
                    setSymbolsResult(result);
                    setSymbolsSet(symbols);
                    currentSymbolsSet.current = symbols._id;
                })
                .catch(console.error)
                .finally(() => {
                    setSymbolsWorking(false);
                })
        }
    },[isNetworkReady, axios, queryString, random, imageSetValue, cancelToken])

    // When Component Loads
    React.useEffect(
        querySymbols,
        [querySymbols]
    );

    React.useEffect(
        queryImageSets,
        [queryImageSets]
    );

    return (
        <React.Fragment>
            {
                (symbolsResult || symbolsWorking) &&
                <Box>
                    <Box display="flex">
                        <Box flexGrow={1} textAlign={"left"}>
                            <Title variant="h5" component="h3">
                                {t('components.example.symbolsType', {symbolsType : startCase(SYMBOLS_TYPE) })}
                            </Title>
                            {
                                !symbolsWorking && symbolsSet?.style && 
                                <Typography>
                                    {t('components.example.influencedByStyle', {style : symbolsSet?.style})}
                                </Typography>
                            }
                            {
                                symbolsWorking &&
                                <Typography>
                                    {t('common.loading')}
                                </Typography>
                            }
                        </Box>
                        {
                            showOrderButton &&
                            <Box sx={{my:'auto'}}>
                                <ViewProductButtonSabian size="small"> 
                                    {t('common.orderYours')} 
                                </ViewProductButtonSabian>
                            </Box>
                        }
                        {
                            !random &&
                            <Box sx={{ml : 1, my : 'auto', width : 200}}>
                                <FormControl fullWidth variant="outlined" size="small" disabled={imageSetsWorking}>
                                    <InputLabel id="select-symbol-set-label" shrink>
                                        Symbol Set
                                    </InputLabel>
                                    <Select
                                        labelId         =  "select-symbol-set"
                                        value           = {imageSetValue}
                                        onChange        ={ (e) => setImageSetValue(e.target.value)}
                                        renderValue     = {(selectedValue) => {
                                            const selectedItem = imageSets.find(({ value }) => value === selectedValue);
                                            if (!selectedItem) 
                                                return null;
                                            return (
                                                <SymbolSetItem 
                                                    label           = {selectedItem.label} 
                                                    image           = {selectedItem.image} 
                                                    greyscale       = {false} 
                                                    size            = "small"
                                                />
                                            );
                                        }}
                                    >
                                        {
                                            (imageSets || []).map((item,ix) => {
                                                const disabled = nonRandomDisabled(maxNonRandom, ix);
                                                return (
                                                    <MenuItem value={item.value} key={item.value} disabled={disabled}>
                                                        <SymbolSetItem 
                                                            label           = {item.label} 
                                                            image           = {item.image} 
                                                            greyscale       = {disabled} 
                                                            size            = "medium"
                                                        />
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        {
                            random &&
                            <Box sx={{ml : 1, my : 'auto'}}>
                                <DebouncedButton 
                                    variant     = "contained" 
                                    size        = "small" 
                                    color       = { theme.palette.mode === 'dark' ? 'secondary' : 'primary' } 
                                    disabled    = { symbolsWorking} 
                                    onClick     = { querySymbols} 
                                    startIcon   = { 
                                        symbolsWorking 
                                            ? <RefreshIcon loading={true}/> 
                                            : <ChangeCircleIcon/> 
                                    }
                                >
                                    {t('components.example.changeStyle')}
                                </DebouncedButton>
                            </Box>
                        }
                    </Box>
                    <Box sx={{position:'relative'}}>
                        <Grid container spacing={0}>
                            {
                                symbolsWorking &&
                                Array(16).fill(undefined).map((_,ix) => (
                                    <Grid key={ix} item xs={6} lg={3}>
                                        <ImageLinkWithOverlay 
                                            overlayEnabled  = {false} 
                                            aspectRatio     = "1"
                                            component       = {preview ? Box : Link}
                                        />   
                                    </Grid>
                                ))
                            }
                            {
                                !symbolsWorking && 
                                symbolsResult.map((data, ix) => (
                                    <Grid key={ix} item xs={6} lg={3}>
                                        <SingleSymbol 
                                            data        = { data }
                                            watermarked = { watermarked } 
                                            symbolStyle = { symbolsSet?.style } 
                                            preview     = { preview }
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        {
                            false && 
                            <>
                                {
                                    imageSets && !imageSetsWorking &&
                                    <JSONViewer src={imageSets} />
                                }
                                {
                                    imageSetValue || "No Value"
                                }
                            </>
                        }
                        {
                            renderOverlay && 
                            renderOverlay()
                        }
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
})

export default SabianSymbolsSampler;