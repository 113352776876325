
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryViewer Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd October 2021

*******************************************************************************************/
import React                            from 'react';
import { useLibrary }                   from './LibraryContext';
import { useUser }                      from './UserContext';
import { useNetwork }                   from './NetworkContext';
import { useCanQuery, useCancelToken }  from 'hooks';

// The Product Context 
const LibraryViewerContext = React.createContext(undefined);

// LibraryViewer Provider
const LibraryViewerProvider = ({children, forceScroll = true}) => {

    const {cancelToken}                 = useCancelToken();
    const {axios}                       = useNetwork();

    // Library Context
    const {
        getLibraryById,
        setLibraryId : setLibraryIdRoot,
    }                                   = useLibrary();
    const { userId }                    = useUser();
    const { socketUsers : socket }      = useNetwork();
    const { allowQueryForUser }         = useCanQuery();

    // Data
    const [libraryId,   setLibraryId]   = React.useState(undefined);
    const [data,        setData]        = React.useState(undefined);
    const [loading,     setLoading]     = React.useState(false);
    const [error,       setError]       = React.useState(undefined);
    const [isOwner,     setIsOwner]     = React.useState(false);

    // Clear Data
    const clear                         = React.useCallback( () => {
        setData(undefined);
        // setLibraryId(undefined);
    }, [])

    // Handle Setting Data
    const handleSetData                 = React.useCallback( (data) => {
        setError(undefined);
        setData(data);
    }, [])

    // Handle Error
    const handleError                   = React.useCallback( (err) => {
        setError(err);
        clear();
    },[clear])

    // Refresh
    const refresh                       = React.useCallback( () => {
        if(libraryId && allowQueryForUser){
            setLoading(true)
            getLibraryById(libraryId, 'libraryViewerContext.refresh')
                .then(handleSetData)
                .catch(handleError)
                .finally(() => {
                    setLoading(false)
                });
        }else{
            clear();
        }

    },[libraryId, allowQueryForUser, getLibraryById, handleSetData, handleError, clear])

    // Make Query
    React.useEffect(refresh, [refresh]);

    const updateLibrary = React.useCallback((data = {}) => new Promise((resolve,reject) => {
        if(!libraryId)
            return reject(new Error('libraryId is required'))

        axios.post(`/api/user/library/${libraryId}`, data, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)

    }), [axios, cancelToken, libraryId])

    // Is Owner
    React.useEffect(() => {
        setIsOwner(Boolean(userId && userId === data?.user))
    },[data, userId])

    const timeoutRef = React.useRef(null);
    React.useEffect(() => {
        clearTimeout(timeoutRef.current);
        if(!forceScroll)
            return;
        timeoutRef.current = setTimeout(() => {
            window.scrollTo({top: 0});
        },0);
        return () => {
            clearTimeout(timeoutRef.current);
        }
    },[forceScroll, libraryId])
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => setLibraryIdRoot(libraryId), [libraryId])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(socket && allowQueryForUser){
            const handleRefresh = (libraryIdRefresh) => {
                if(libraryId && libraryIdRefresh === libraryId){
                    refresh();
                }
            }
            socket.on('refresh_library',    handleRefresh);
            socket.on('refresh_libraries',  handleRefresh);
            return () => {
                socket.off('refresh_library',   handleRefresh);
                socket.off('refresh_libraries', handleRefresh);
            }
        }
    },[allowQueryForUser, libraryId, refresh, socket])

    // Context values
    const value = React.useMemo(() => ({
        loading,
        libraryId,
        setLibraryId,
        updateLibrary,
        data,
        setData,
        refresh,
        error,
        isOwner,
        isAvailable         : Boolean(data?.available),
        isPublic            : Boolean(data?.public),
        commentsEnabled     : Boolean(data?.commentsEnabled)
    }), [data, error, isOwner, libraryId, loading, refresh, updateLibrary]);

    return (
        <LibraryViewerContext.Provider value={value}>
            {children}
        </LibraryViewerContext.Provider>
    )
}

// LibraryViewer Consumer
const LibraryViewerConsumer = ({children}) => {
    return (
        <LibraryViewerContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LibraryViewerConsumer must be used within LibraryViewerProvider');
                }
                return children(context)
            }}
        </LibraryViewerContext.Consumer>
    )
}

// useLibraryViewer Hook
const useLibraryViewer = () => {
    const context = React.useContext(LibraryViewerContext);
    if(context === undefined)
        throw new Error('useLibraryViewer must be used within LibraryViewerProvider');
    return context;
}

export {
    LibraryViewerProvider,
    LibraryViewerConsumer,
    useLibraryViewer
}