/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useStateEphemeral
********************************************************************************************
A state that resets to undefined after a period of time

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th March 2021

********************************************************************************************/
import React    from 'react';
import moment   from 'moment';

const noop = () => {};

export const useStateEphemeral = (initialState, duration = 1000, ephemeralState = undefined, onCountDown = noop) => {

    // Message and Set Message hook
    const [state, setState] = React.useState(undefined);

    // Method to erase the state
    const erase = React.useCallback( () => {
        setState(ephemeralState);
        onCountDown(0);
    }, [ephemeralState, onCountDown]);
    
    // Update message
    React.useEffect(() => setState(initialState), [initialState]);
    
    // Timer
    const intervalRef = React.useRef(null);
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {
        clearTimeout(timeoutRef.current);
        clearInterval(intervalRef.current);

        if(state === ephemeralState)
            return;

        if(isNaN(duration) || duration <= 0)
            return;
            
        const start         = moment();

        timeoutRef.current     = setTimeout(erase, duration);
        intervalRef.current    = setInterval(() => {
            let elapsed     = moment().diff(start)
            let remaining   = Math.max(duration - elapsed, 0);
            if(remaining <= 0)
                clearInterval(intervalRef.current);
            onCountDown(remaining);
        },100)

        // Cleanup
        return () => {
            clearTimeout(timeoutRef.current);
            clearInterval(intervalRef.current);
        }

    },[duration, ephemeralState, erase, onCountDown, state])
    
    // Return the Message and SetMessage hook
    return [state, setState];
};

export default useStateEphemeral;