/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Tickets Thread Viewer
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th February 2022

*******************************************************************************************/
import React                            from 'react';
import {isEmpty,isNil,stubFalse}        from 'lodash';
import moment                           from 'moment';
import {
    styled,
    useMediaQuery,
    alpha,
    useTheme,
    Grid,
    Box,
    Typography,
    DialogActions as DialogActionsMUI,
}                                       from '@mui/material';
import { Alert }                        from '@mui/material';
import MessageIcon                      from '@mui/icons-material/Message';
import LocalActivityIcon                from '@mui/icons-material/LocalActivity';
import CancelIcon                       from '@mui/icons-material/Cancel';
import {
    Gravatar,
    LoadingData,
    RemoveWithConfirmationButton as RemoveButton,
    JSONViewer,
    ConfirmationButton,
    NewChip,
    ColorChip,
    SkeletonFancyWaitingForNetwork,
    ObjectId,
    Button,
    Linkify,
    SpaceBox,
    ItemPaper
}                                       from 'components';
import {useCancelToken}                 from 'hooks';
import {
    TicketForm,
    TicketMessageForm
}                                       from 'components/forms';
import {
    useTicket,
    useNetwork,
    useUser,
    useLocale
}                                       from 'contexts';
import {useSize}                        from 'hooks';

const GRAVATAR_SIZE             = 48;
const MESSAGE_LINE_THICKNESS    = 2;
const DEBUG                     = false;
const DIALOG_HEIGHT             = 50, 
      ALERT_HEIGHT              = 50;
const BASE_API_URL              = '/api/user/ticket';
const TICKET_MESSAGE_FORM_ID    = 'ticketMessageForm';

const PromptContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'hasMessages'
})(({theme, hasMessages = false}) => ({
    ...(hasMessages && {
        marginLeft : GRAVATAR_SIZE +'px'
    })
}));

const MessageLine = styled(Box)(({theme}) => ({
    position    : 'absolute',
    top         : 0,
    bottom      : 0,
    left        : 0,
    // Half of gravatar plus gravatar margin minus half of border thickness
    marginLeft  : GRAVATAR_SIZE/2 - MESSAGE_LINE_THICKNESS/2,
    borderLeft  : `${MESSAGE_LINE_THICKNESS}px solid ${theme.palette.action.selected}`
}));

const DateRangeSpacer = styled(Box)(({theme}) => ({
    marginLeft      : theme.spacing(2),
    marginRight     : theme.spacing(2),
    marginBottom    : 'auto',
    marginTop       : 'auto',
    borderBottom    : `1px solid ${alpha(theme.palette.text.secondary,0.15)}`
}));

const ScrollGridContainer = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('md')] : {
        // paddingRight : theme.spacing(1),
        '& > * + *' : {
           paddingLeft : theme.spacing(1),
        },
        height          : '100%', 
        // overflow        : 'hidden'
    }
}));

const ScrollGridBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('md')] : {
        // height          : '100%', 
        position        : 'relative'
    }
}));

const ScrollBoxMaster = styled(Box)(({theme}) => ({
    display         : "flex" ,
    flexDirection   : "column" ,
    // height          : "100%",
    overflow        : "scroll",
    height          : "fit-content", 
    [theme.breakpoints.up('md')] : {
         height          : "100%" 
    }
}));

const DialogActions = styled(DialogActionsMUI)(({theme}) => ({
    padding         : theme.spacing(2),
    position        : 'absolute',
    bottom          : 0,
    left            : 0,
    right           : 0,
    background      : theme.palette.background.paper
}));

const noop = () => {}

export const TicketsThreadViewer = ({
    ticketId : ticketIdIn, 
    adminMode               = false, 
    showTitle               = true,
    showDetails             = true,
    onCancel : handleCancel = null,
    onClose  : handleClose  = noop,
    ...props
}) => {
    
    const theme                                 = useTheme();
    const {cancelToken }                        = useCancelToken();
    const {
        isAuthenticated,
        userId,
        isAdmin,
        emailMD5
    }                                           = useUser();
    const {
        axios, 
        socketUsers : socket, 
        isNetworkReady
    }                                           = useNetwork();
    const {formatDateTime}                      = useLocale();
    const {
        admin,
        setAdmin,
        // refreshTicket : refreshTicketContext,
        submitTicketMessage,
        includeAssigned,
        setIncludeAssigned,
    }                                           = useTicket(); 
    // const windowFocused                         = useWindowFocus();
    const ticketId                              = React.useMemo(() => ticketIdIn, [ticketIdIn])
    
    const actionsRef                            = React.useRef();
    const {height:heightActions}                = useSize(actionsRef);

    const alertRef                              = React.useRef();
    const {height:alertHeight}                  = useSize(alertRef);

    const contentRef                            = React.useRef();
    // const {height:contentHeight}                = useSize(contentRef);

    // const [ticketId,    setTicketId]            = React.useState(ticketIdIn);
    const [queried,     setQueried]             = React.useState(false);
    const [loading,     setLoading]             = React.useState(false);
    const [claiming,    setClaiming]            = React.useState(false);
    const [closing,     setClosing]             = React.useState(false);
    const [postReply,   setPostReply]           = React.useState(false);
    const [deleteConf,  setDeleteConf]          = React.useState(false);

    const [ticketData,  setTicketData]          = React.useState({});

    // Update TicketID
    // React.useEffect(() => setTicketId(ticketIdIn), [ticketIdIn]);

    // Update admin state
    React.useEffect(()=>{
        let newVal = Boolean(adminMode);
        if(newVal !== admin) setAdmin(newVal)
    },[admin, adminMode, setAdmin])

    const resetTicketData                       = React.useCallback( () => {
        // setTicketId(undefined);
        setTicketData({});
        setQueried(false);
    }, []);
    const handleEnablePostReply                 = React.useCallback(() => setPostReply(true), []);
    const handleDisablePostReply                = React.useCallback(() => setPostReply(false), []);

    // Scroll to Delivery ID
    const timeoutRef = React.useRef(null);
    const scrollToMessageForm = React.useCallback( () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            const el = document.getElementById(TICKET_MESSAGE_FORM_ID);
            if(el) {
                el.scrollIntoView({
                    behavior    : 'smooth', 
                    block       : 'start', 
                    inline      : 'start'
                });
            }
        },250);
    },[])

    const queryTicket = React.useCallback( () => new Promise((resolve,reject) => {
        if(ticketId && isNetworkReady && isAuthenticated){
            axios.get(`${BASE_API_URL}/${ticketId}?admin=${Boolean(admin)}`, { cancelToken })
                .then(resolve)
                .catch(reject)
        }else{
            resolve({});
        }
    }),[ticketId, isNetworkReady, isAuthenticated, axios, admin, cancelToken]);

    // Refresh the actual ticket data
    const refreshTicket = React.useCallback( () => {
        if(ticketId && isNetworkReady && isAuthenticated){
            setLoading(true);
            queryTicket(ticketId)
                .then(({data,errors,message}) => {
                    if(!isEmpty(errors))
                        throw new Error(message);
                    return data
                })
                .then(setTicketData)
                .catch(typeof handleCancel === 'function' ? handleCancel : resetTicketData)
                .finally(()=>{
                    setQueried(true);
                    setLoading(false);
                })
        }else{
            resetTicketData();
            setQueried(true);
        }
    },[handleCancel, isAuthenticated, isNetworkReady, queryTicket, resetTicketData, ticketId])

    // View Ticket
    React.useEffect( () => {
        if(isNetworkReady && isAuthenticated && !isEmpty(ticketData)){
            const url = `${BASE_API_URL}/${ticketData._id}/view?admin=${Boolean(admin)}`;
            axios.post(
                url, 
                {}, 
                {cancelToken}
            ).catch(console.error)
        }
    },[isNetworkReady, isAuthenticated, ticketData, axios, admin, cancelToken])

    // Claim Ticket
    const claimTicket = React.useCallback( () => new Promise((resolve,reject) => {
        if(isNetworkReady && isAuthenticated && !isEmpty(ticketData)){
            setClaiming(true);
            axios.post(`${BASE_API_URL}/${ticketData._id}/claim`, {}, {cancelToken})
                .then(result => {
                    // after executed, this item will be assigned, so better include assigned in query
                    if(!includeAssigned) setIncludeAssigned(true);
                    resolve(result);
                })
                .catch(reject)
                .finally(()=>{
                    setClaiming(false);
                })
        }
    }),[axios, cancelToken, includeAssigned, isAuthenticated, isNetworkReady, setIncludeAssigned, ticketData])

    // Close Ticket
    const timeoutRef2 = React.useRef(null);
    const closeTicket = React.useCallback(() => new Promise((resolve,reject) => {
        clearTimeout(timeoutRef2.current);
        if(ticketData){
            setClosing(true);
            axios.post(`${BASE_API_URL}/${ticketData._id}/close`, {}, {cancelToken})
                .then(result => {
                    resolve(result);
                    timeoutRef2.current = setTimeout(handleClose,1000);
                })
                .catch(reject)
                .finally(()=>{
                    setClosing(false);
                })
        }
    }),[axios, cancelToken, handleClose, ticketData])

    // Load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => refreshTicket(ticketId), [ticketId]);

    // If change ticket id, turn off the form
    React.useEffect(() => setPostReply(false), [ticketId]);

    // If change ticket id, reset queried status
    React.useEffect(() => setQueried(false), [ticketId]);

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(socket){
            const handleRefresh = (id) => {
                console.log('refresh ticket');
                if(ticketId && id === ticketId) 
                    refreshTicket();
            }
            socket.on('refresh_ticket', handleRefresh)
            return () => {
                socket.off('refresh_ticket', handleRefresh);
            }
        }
    },[ticketId, socket, refreshTicket])

    // Handle submit message. If no errors, disable reply form
    const handleSubmitMessage = (formData) => new Promise(resolve => {
        submitTicketMessage(formData)
            .then(() => {
                handleDisablePostReply();
                refreshTicket();
                resolve({})
            })
            .catch(({errors}) => {
                resolve(errors)
            })
    })

    const isAssigned        = React.useMemo(() => Boolean(ticketData?.isAssigned), [ticketData?.isAssigned]);
    const hasTicketData     = React.useMemo(() => !(isEmpty(ticketData) || isNil(ticketData)), [ticketData]);
    const messageQuantity   = React.useMemo(() => hasTicketData ? (ticketData?.messages || []).length : 0, [hasTicketData, ticketData?.messages]);
    const hasMessages       = React.useMemo(() => hasTicketData && messageQuantity > 0, [hasTicketData, messageQuantity]);
    const isClosed          = React.useMemo(() => Boolean(hasTicketData && userId && ticketData?.closed), [hasTicketData, ticketData?.closed, userId]);
    const isManager         = React.useMemo(() => Boolean(hasTicketData && userId && ticketData?.manager === userId), [hasTicketData, ticketData?.manager, userId]);
    const isUser            = React.useMemo(() => Boolean(hasTicketData && userId && ticketData?.user?._id === userId), [hasTicketData, ticketData?.user?._id, userId]);
    const isNotOwner        = React.useMemo(() => Boolean(hasTicketData && ticketData?.user?._id && userId !== ticketData?.user?._id), [hasTicketData, ticketData?.user?._id, userId]);
    const canPostMessages   = React.useMemo(() => (isManager || isUser) && ((admin && isNotOwner) || isAssigned), [admin, isAssigned, isManager, isNotOwner, isUser]);
    const showMessageButton = React.useMemo(() => Boolean(!postReply && canPostMessages), [canPostMessages, postReply]);
    const showCloseButton   = React.useMemo(() => Boolean(!postReply && [ticketData?.user?._id, ticketData.manager].includes(userId) && !ticketData?.closed), [postReply, ticketData?.closed, ticketData.manager, ticketData?.user?._id, userId]);

    React.useEffect(() => {
        if(hasMessages)
            scrollToMessageForm();
    },[ticketData, hasMessages, scrollToMessageForm])

    React.useEffect(() => {
        if(postReply)
            scrollToMessageForm();
    },[postReply, scrollToMessageForm])

    const AlertNotAssigned = React.useCallback(({containerProps={}}) => {
        if(isAssigned || isClosed)
            return null;
        return (
            <Box pb={1} {...containerProps}>
                <Alert 
                    severity="warning"
                    action = {
                        !isAssigned && isAdmin && isNotOwner &&
                        <ConfirmationButton 
                            startIcon   = {<LocalActivityIcon/>} 
                            disabled    = {deleteConf || claiming} 
                            onClick     = {claimTicket} 
                            variant     = "contained" 
                            color       = "primary" 
                            size        = "small"
                            style       = {{width:'150px'}}
                        >
                            Claim Ticket?
                        </ConfirmationButton>
                    }
                >
                    This ticket is yet to be assigned to a manager
                </Alert>
            </Box>
        )
    },[isAssigned, isClosed, isAdmin, isNotOwner, deleteConf, claiming, claimTicket]);

    const AlertClosed = React.useCallback( ({containerProps={}}) => {
        if(!isClosed) 
            return null;
        return (
            <Box pb={1} {...containerProps}>
                <Alert severity="error">
                    This ticket is closed
                </Alert>
            </Box>
        )
    },[isClosed])

    const TicketsFormGroup = React.useCallback( ({sticky = stubFalse}) => {
        return (
            <Box pb={1} display="flex" style={sticky ? {position:'sticky',top:0} : {}}>
                <Box flexShrink={1}>
                    <Gravatar 
                        size        = {GRAVATAR_SIZE} 
                        emailMD5    = {ticketData?.user?.emailMD5}
                    />
                </Box>
                <Box flexGrow={1} pl={1} style={{overflow:'hidden'}}>
                    {  
                        (showTitle || showDetails) &&
                        <Box display="flex">
                            <Box flexGrow={1}>
                                {
                                    showTitle &&
                                    <Typography variant="h5">
                                        Ticket Details
                                    </Typography>
                                }
                                {
                                    showDetails &&
                                    <Typography component='div' variant="body2" gutterBottom style={{whiteSpace:'nowrap'}}>
                                        Ticket {ticketData?.id ? <ObjectId value={ticketData.id}/> : ''}{ticketData?.createdAt && `, Created: ${formatDateTime(moment(ticketData?.createdAt))}`} <ColorChip size="small" label="Read Only" color="secondary" />
                                    </Typography>
                                }
                            </Box>
                        </Box>
                    }

                    <TicketForm
                        readOnly     = {true}
                        disabled     = {!true}
                        formData     = {ticketData}
                        textRows     = {null}
                        FormProps    = {{
                            showCancelButton    : false,
                            showResetButton     : false,
                            showSubmitButton    : false,
                            showObjectId        : false
                        }}
                    />
                </Box>
            </Box>
        )
    },[formatDateTime, showDetails, showTitle, ticketData])

    const showActions   = React.useMemo(() => !postReply, [postReply]); //true || showMessageButton || showCloseButton;
    const showAlert     = React.useMemo(() => isClosed || !isAssigned, [isAssigned, isClosed]);
    
    const mdUp          = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Box width = "100%" style={{height:'100%' /*height : `calc(100% - ${(showActions ? 1 : 0) * DIALOG_HEIGHT}px)`*/}}>
            {
                hasTicketData && queried &&
                <>
                    <ScrollBoxMaster id = "ticketsthread">
                        <Box ref={alertRef}>
                            {
                                showAlert && 
                                <Box>
                                    <AlertNotAssigned />
                                    <AlertClosed />
                                </Box>
                            }
                        </Box>
                        <ScrollGridBox 
                            id          = "scrollGridBox" 
                            ref         = {contentRef}
                            style       = {{
                                height : `calc(100% - ${showAlert ? (alertHeight + parseInt(theme.spacing(4))) : 0}px - ${showActions ? 0 * (heightActions + parseInt(theme.spacing(4))) : 0}px)`
                            }}
                        >

                            <Grid component={ScrollGridContainer} container spacing={0}>

                                <Grid item xs={12} md={6} lg={6} sx={{ position : 'relative' }} >   
                                    <Box id="ticketsFormGroupContainer" sx={{position: {xs:'relative',md:'absolute'}, pb:2, top:0,left:0,bottom:0,right:0,overflow:'scroll'}}>
                                        <TicketsFormGroup sticky={mdUp}/>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6} sx={{ position : 'relative'}}>
                                    
                                    <Box id="ticketsMessagesGroupContainer" sx={{position: {xs:'relative',md:'absolute'}, ml : {xs : 0, md : 2}, pb:2, top:0,left:0,bottom:0,right:0,overflow:'scroll'}}>
                                        
                                        <Box sx={{position:'relative'}}>
                                            {
                                                hasMessages &&
                                                <MessageLine />
                                            }
                                            <Box id="messages" sx={{"& > * + *" : { mt : 2 }}}>
                                                {
                                                    (ticketData?.messages || []).map((message,ix,arr) => {
                                                        const messageCreated    = moment(message.createdAt);
                                                        const hasUserSeen       = ticketData.userSeenAt     ? moment(ticketData.userSeenAt).isSameOrAfter(messageCreated) : false
                                                        const hasManagerSeen    = ticketData.managerSeenAt  ? moment(ticketData.managerSeenAt).isSameOrAfter(messageCreated) : false;
                                                        const isNew             = (isManager && !hasManagerSeen) || (isUser && !hasUserSeen);
                                                        return (
                                                            <Box key={ix} display="flex">
                                                                <Box flexShrink={1}>
                                                                    <Gravatar 
                                                                        size        = {GRAVATAR_SIZE} 
                                                                        emailMD5    = {message?.user?.emailMD5}
                                                                    />
                                                                </Box>
                                                                <Box flexGrow={1} pl={1}>
                                                                    <Box display="flex">
                                                                        <Box flexShrink = {1}>
                                                                            <Typography 
                                                                                component   = 'span' 
                                                                                variant     = 'body1' 
                                                                                style       = {{
                                                                                    fontWeight: isNew ? 800 : 400
                                                                                }}
                                                                            >
                                                                                {message?.user?.name || `${message?.user?.firstName} ${message?.user?.lastName}`}{isNew && <NewChip style={{marginLeft:theme.spacing(1)}}/>}
                                                                            </Typography>
                                                                        </Box>
                                                                        <DateRangeSpacer flexGrow={1} />
                                                                        <Box flexShrink={1}>
                                                                            <Typography 
                                                                                component   = 'span' 
                                                                                variant     = 'body2' 
                                                                                style       = {{
                                                                                    fontWeight  : isNew ? 800 : 400,
                                                                                    color       : theme.palette.text.secondary, 
                                                                                    paddingLeft : theme.spacing(1),
                                                                                    paddingRight: theme.spacing(1),
                                                                                    fontStyle   : 'italic',
                                                                                    whiteSpace  : 'nowrap'
                                                                                }}
                                                                            >
                                                                                {moment(message.createdAt).fromNow()} 
                                                                            </Typography>
                                                                        </Box>
                                                                        {
                                                                            false && 
                                                                            <DateRangeSpacer flexGrow={1} style={{maxWidth:100}}/>
                                                                        }
                                                                        {
                                                                            false && 
                                                                            <Box flexShrink={1}>
                                                                                <Typography 
                                                                                    component   = 'span' 
                                                                                    variant     = 'body2' 
                                                                                    style       = {{
                                                                                        fontWeight      : isNew ? 800 : 400,
                                                                                        color           : theme.palette.text.secondary, 
                                                                                        paddingRight    : theme.spacing(1),
                                                                                        paddingLeft     : 0
                                                                                    }}
                                                                                >
                                                                                    {formatDateTime(moment(message.createdAt))} 
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                    </Box>
                                                                    
                                                                    {  
                                                                        message?.text && 
                                                                        <Linkify>
                                                                            <Box pr={1} pt={0.5}>
                                                                            {
                                                                                message?.text?.split('\n').map((para,ix,arr) => (
                                                                                    <Typography key={ix} variant='body2' align="justify" paragraph={ix < arr.length - 1}>
                                                                                        {para}
                                                                                    </Typography>
                                                                                ))
                                                                            }
                                                                            </Box>
                                                                        </Linkify>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    
                                        {
                                            !loading && !postReply && (!hasMessages || !isAssigned || isClosed) &&
                                            <PromptContainer hasMessages={hasMessages} sx={{mt : {xs : 0, md : 3}}}>
                                                <ItemPaper sx={{border : theme => `1px solid ${theme.palette.divider}`, borderRadius:1}}>
                                                    <SpaceBox width="fit-content">
                                                        {   
                                                            [
                                                                !isClosed && !isAssigned                        ? 'Ticket not assigned to manager'  : undefined,
                                                                !isClosed && !hasMessages && isAssigned         ? 'There are no messages submitted' : undefined,
                                                                isClosed                                        ? 'This ticket is has been closed'  : undefined
                                                            ].filter(Boolean).map((msg,ix) => (
                                                                <Typography gutterBottom align="center" key={ix}>
                                                                    {msg}
                                                                </Typography>
                                                            ))
                                                        }
                                                    </SpaceBox>
                                                </ItemPaper>
                                            </PromptContainer>
                                        }

                                        <Box id={TICKET_MESSAGE_FORM_ID} mt={2} pb={2}>

                                            {
                                                loading && 
                                                <div style={{width:'100%', textAlign:'center', padding:theme.spacing(2)}}>
                                                    <LoadingData label="Loading Messages"/> 
                                                </div>
                                            }

                                            {
                                                queried && canPostMessages && postReply &&
                                                <Box id="postGroup" display="flex" mr={1}>
                                                    <Box style={{position:'relative'}}>
                                                        <MessageLine style={{height:GRAVATAR_SIZE + theme.spacing(2) + theme.spacing(1)}}/>
                                                        <Gravatar 
                                                            size        = {GRAVATAR_SIZE} 
                                                            emailMD5    = {emailMD5}
                                                        />
                                                    </Box>
                                                    <Box flexGrow={1} pl={1}>
                                                        <TicketMessageForm 
                                                            disabled            = {closing || loading || claiming}
                                                            variant             = 'filled'
                                                            onSubmit            = {handleSubmitMessage}
                                                            formData            = {{
                                                                user    : userId,
                                                                ticket  : ticketData.id
                                                            }}
                                                            onCancel            = {handleDisablePostReply}
                                                            showCancelButton    = {true}
                                                            submitText          = {'Submit'}
                                                        />
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ScrollGridBox>

                        {  
                            showActions &&
                            <DialogActions ref={actionsRef}>
                                <Box display="flex" width='100%'>
                                    <Box flexGrow={1}>
                                        {
                                            showCloseButton && 
                                            <RemoveButton 
                                                style                   = {{width:200, whiteSpace:'nowrap'}}
                                                onConfirmationRequired  = {(value) => setDeleteConf(value)} 
                                                confirmationText        = "Are you sure?" 
                                                startIcon               = {<LocalActivityIcon/>} 
                                                disabled                = {closing} 
                                                onClick                 = {closeTicket} 
                                                variant                 = "text" 
                                                color                   = "error"
                                            >
                                                Finalize / Close Ticket
                                            </RemoveButton>
                                        }
                                    </Box>
                                    <Box flexShrink={1}>
                                        {
                                            mdUp && handleCancel && 
                                            <Button 
                                                disabled                = {closing}
                                                startIcon               = {<CancelIcon/>} 
                                                variant                 = "text" 
                                                color                   = "error" 
                                                onClick                 = {handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        }
                                        {
                                            showMessageButton && 
                                            <Button 
                                                disabled                = {deleteConf || closing}
                                                startIcon               = {<MessageIcon/>} 
                                                variant                 = "contained" 
                                                color                   = "secondary" 
                                                onClick                 = {handleEnablePostReply}
                                                style                   = {{whiteSpace:'nowrap',marginLeft:theme.spacing(1)}}
                                            >
                                                {
                                                    isClosed 
                                                        ? 'Reopen and Post Message' 
                                                        : 'Post Message'
                                                }
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            </DialogActions>
                        }
                        <Box id = "bottomspacer" style = {{minHeight : !postReply ? (DIALOG_HEIGHT + (showAlert ? ALERT_HEIGHT : 0)) : 0 }} />  
                    </ScrollBoxMaster>
                </>
            }

            {
                (!hasTicketData || !queried) &&
                <SkeletonFancyWaitingForNetwork height={'calc(100% - 16px)!important'} />
            }
            {
                DEBUG && hasTicketData &&
                <JSONViewer src={ticketData}/>   
            }
        </Box>
    );
}


export default TicketsThreadViewer;