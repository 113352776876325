/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
React Geocode Lookup
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st November 2020

*******************************************************************************************/

import React                            from 'react';
import { Box, TextField }               from '@mui/material';
import { LoadingData }                  from 'components';
import { useGeocode }                   from 'hooks';
import { withTranslation }              from './hoc';

// Constants
const LOOKUP_DELAY  = 500;
const MIN_LENGTH    = 2;

const noopGeo = (geo) => {}

// Lookup Component
export const GeoLookup = withTranslation( ({
    t,
    value   : v     = undefined,
    onChange        = noopGeo, 
    ...rest
} = {}) => {
    const {lookupAddress}           = useGeocode();
    const [value,   setValue]       = React.useState(v); React.useEffect(() => setValue(v),[v]);
    const [working, setWorking]     = React.useState(false);
    const [result,  setResult]      = React.useState({success:false, lat:undefined, lng:undefined, address:undefined, error:undefined}); 
    const handleChange              = React.useCallback( (event) => setValue(event.target.value), []);

    React.useEffect(()=>{
        onChange(result);
    },[result,onChange])

    // Execute Query on Change to Value
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {

        clearTimeout(timeoutRef.current);

        let hasValue    = Boolean(value), 
            opts        = {success: false, lat: undefined, lng: undefined, address: undefined, error: undefined}
        
        if(hasValue && value.length < MIN_LENGTH)
            opts.error  = t('components.geoLookup.lengthMinChar',{minChar:MIN_LENGTH})
        
        setResult(opts);

        if(!hasValue || value.length < MIN_LENGTH)
            return;

        // Get latitude & longitude from address.
        timeoutRef.current = setTimeout(() => {
            // OK, Now try and execute
            setWorking(true);
            lookupAddress(value)
                .then(setResult)
                .finally(()=>{
                    setWorking(false);
                })
        },LOOKUP_DELAY)

        // Cleanup
        return () => {
            clearTimeout(timeoutRef.current);
        }

    },[t, lookupAddress, value])

    return (
        <TextField 
            label           = {t('components.geoLookup.searchAddress')}
            defaultValue    = {value}
            onChange        = {handleChange} 
            error           = {Boolean(result.error)} 
            variant         = "filled"
            InputProps      = {{
                endAdornment: ( 
                    working && 
                    <Box sx={{px:1}}>
                        <LoadingData size={20} color="secondary" /> 
                    </Box>
                ),
            }}
            fullWidth
            {...rest}
            helperText      = {result.error || rest.helperText} 
        />
    )
});

export default GeoLookup;