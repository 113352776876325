/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Error Fallback
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd January 2022

*******************************************************************************************/
import React                from 'react';
import { useInternet }      from 'hooks/services/useInternet';
import { 
    styled,
    useTheme, 
    Box, 
    Button, 
    Typography 
}                           from '@mui/material';
import {
    Logo,
    RefreshIcon
}                           from 'components';
import { withTranslation }  from './hoc';


const setWithExpiry = (key, value, ttl) => {
  const item = {
      value: value,
      expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

const getWithExpiry = (key) => {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) 
      return null;
  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;
  if (isExpired) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}

const Root = styled(Box)(({theme}) => ({
    position    : 'absolute',
    top         : '45%',
    left        : 0,
    right       : 0,
    paddingTop  : theme.spacing(2),
}));
const Container = styled(Box)(({theme}) => ({
    textAlign   : 'center',
    transform   : 'translateY(-50%)',
    '& > *' : {
        paddingBottom : theme.spacing(4)
    }
}));
const LogoContainer = styled(Box)({
    width       : 300,
    maxWidth    : '40%',
    margin      : 'auto'
});
const TypographyContainer = styled(Box)({
});
const ButtonContainer = styled(Box)({
});


export const ErrorFallback = withTranslation( ({ 
    t,
    error
}) => {

    const theme                     = useTheme(); 
    const {isOnline}                = useInternet();
    const [reloading, setReloading] = React.useState(false);
    const resetReLoading            = React.useCallback( () => setReloading(false), []);

    // Handle failed lazy loading of a JS/CSS chunk.
    React.useEffect(() => {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                window.location.reload();
            }
        }
    }, [error]);

    React.useEffect(() => {
        window.addEventListener('load', resetReLoading);
        return () => {
            window.removeEventListener('load', resetReLoading)  
        }
    },[resetReLoading])

    const timeoutRef = React.useRef(null);
    const handleReload = React.useCallback(() => {
        clearTimeout(timeoutRef.current);
        setReloading(true);
        timeoutRef.current = setTimeout(window.location.reload, 1000);
    },[]);

    return (
        <Root id="errorfallback">
            <Container>
                <LogoContainer>
                    <Logo dark={theme.palette.mode === 'light'} style={{width:'100%',minWidth:'unset',maxHeight:'unset'}}/>
                </LogoContainer>
                <TypographyContainer>
                    <Typography variant="h3" paragraph>
                        {t('components.errorFallback.mainMessage')}
                    </Typography>
                    {   
                        error?.message && 
                        <Box sx={{width:'80vh',mx:'auto',textAlign:'justify'}}>
                            <Typography sx={{fontStyle:'italic'}}>
                                {error?.message}
                            </Typography>
                        </Box>
                    }
                </TypographyContainer>
                <ButtonContainer>
                    <Button 
                        startIcon   = {<RefreshIcon loading={reloading}/>} 
                        disabled    = {!isOnline || reloading} 
                        onClick     = {handleReload} 
                        variant     = "contained" 
                        size        = "large" 
                        color       = "primary"
                    >
                        {t('components.errorFallback.reloadPage')}
                    </Button>
                </ButtonContainer>
            </Container>
        </Root>
    );
})

export default ErrorFallback;