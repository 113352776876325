/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alert with timeout
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                            from 'react';
import { Alert }                        from '@mui/material';

const noop = () => {}

export const AlertTimeout = ({
    children,
    severity            = "error",
    timeout : duration  = 2000,
    onTimeout           = noop,
    ...props
}) => {
    
    const [show, setShow] = React.useState(true); 
    
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {

        clearTimeout(timeoutRef.current)

        if(children && duration > 0){

            setShow(true);
            
            timeoutRef.current = setTimeout(() => {
                setShow(false);
                onTimeout();
            },duration)

            return () => {
                clearTimeout(timeoutRef.current)
            }
        }
    },[children, duration, onTimeout]) 

    // result
    if(!show || !children) 
        return null;

    return (
        <Alert severity={severity} {...props}>
            {children}
        </Alert>
    )
}

export default AlertTimeout;