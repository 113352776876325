/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Header Scripts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th November 2022

*******************************************************************************************/
import React                            from 'react';
import { Helmet }                       from "react-helmet";
// import font                             from 'resources/fonts/HistoraBeralin-vmV9O.otf'

export const HeaderScripts = () => (
    <Helmet>
        { /*<!-- INJECT THE ASTROCHART SCRIPT -->*/ }
        <style type="text/css"></style>
    </Helmet>
)

/*
{`
    @font-face {
        font-family: 'HistoraBeralin';
        src: url('${font}') format('opentype');
    }
`}
*/

export default HeaderScripts;