/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Menu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    lighten, 
    Box, 
    Popover 
}                                       from '@mui/material';
import { RootContainer }                from 'components';
import MenuButton                       from './MenuButton';

const obj     = {};
const TIMEOUT = 100;

const ArrowContainer = styled(Box)({
    position        : "absolute",
    bottom          : 0,
    left            : '50%', 
    transform       : 'translatex(-50%)'
})

const Arrow = styled(Box,{
    shouldForwardProp : prop => prop !== 'fadein' && prop !== 'fadeout'
})(({theme,fadein = false, fadeout = false}) => {
    const borderColor = theme.palette.mode === 'light' 
        ? lighten(theme.palette.primary.light,0.75) 
        : theme.palette.background.paper
    return {
        width               : 0,
        height              : 0,
        borderLeft          : '8px solid transparent',
        borderRight         : '8px solid transparent',
        borderBottomColor   : borderColor,
        borderBottom        : `8px solid`,
        ...(fadein && {
            transition      : 'opacity 2s',
            opacity         : '1'
        }),
        ...(fadeout && {
            opacity         : '0',
            pointerEvents   : 'none'
        })
    }
})

export const Menu = ({
    children,
    label                   = "Menu", 
    openOnHover             = false,
    ButtonProps             = obj,
    MenuProps               = obj,
    showArrow               = false,
    forceOpen               = false,
    ...props 
}) => {
    
    const [mouseOverButton, setMouseOverButton]     = React.useState(false);
    const [mouseOverMenu,   setMouseOverMenu]       = React.useState(false);
    const [anchorEl,        setAnchorEl]            = React.useState()
    
    const handleMouseOver= React.useCallback( (event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleButtonClick = React.useCallback(  event => {
        setAnchorEl(event.currentTarget);
        setMouseOverButton(true);   // Force
    }, []);
    
    const handleClose =  React.useCallback( () => {
        setMouseOverButton(false);  // Reset
        setMouseOverMenu(false);    // Reset
    }, []);
    
    const enterButton =  React.useCallback( () => {
        setMouseOverButton(true);  
    }, []);
    
    const timeoutRef = React.useRef(null);
    const leaveButton =  React.useCallback( () => {
        clearTimeout(timeoutRef.current);
        // Set a timeout so that the menu doesn't close before the user has time to
        // move their mouse over it
        timeoutRef.current = setTimeout(() => {
            setMouseOverButton(false);
        }, TIMEOUT);
    }, []);

    const open = React.useMemo(() => (
        Boolean(mouseOverButton || mouseOverMenu)
    ), [mouseOverButton, mouseOverMenu]);

    const extras = React.useMemo(() => (
        openOnHover 
            ? { onMouseOver : handleMouseOver, onMouseEnter : enterButton, onMouseLeave : leaveButton } 
            : {}
    ), [enterButton, handleMouseOver, leaveButton, openOnHover]);

    const paperProps = React.useMemo(() => ({
        style: { transform: 'translateY(40px)' }
    }), []);

    return (
        <React.Fragment>
            <Box position="relative" display="inline-block" {...props.containerProps}>
                {
                    props.render && 
                    props.render({
                        handleClick:handleButtonClick
                    })
                }
                {
                    !props.render &&
                    <MenuButton onClick={handleButtonClick} disableRipple {...extras} {...props} {...ButtonProps}>
                        {label}
                    </MenuButton>
                }
                {
                    open && showArrow && 
                    <ArrowContainer>
                        <Arrow fadein={open} fadeout={!open}/>
                    </ArrowContainer>
                }
            </Box>
            <Popover
                anchorEl        = {anchorEl}
                open            = {forceOpen || open}
                onClick         = {handleClose} // click on child
                // onBlur          = {handleClose} // click elsewhere
                onMouseLeave    = {handleClose}
                onClose         = {handleClose}
                PaperProps      = {paperProps}
                {...MenuProps}
                sx              = {{
                    '& .MuiPopover-paper' : {
                        padding         : theme => theme.spacing(2),
                        borderRadius    : theme => theme.spacing(1)
                    }
                }}
            >   
                <RootContainer>
                    {children}
                </RootContainer>
            </Popover>
        </React.Fragment>
    )
};

export default Menu;
