/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Playlist
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                        from 'react';
import moment                       from 'moment';
import {
    Box,
    Typography
}                                   from '@mui/material';
import LoginIcon                    from '@mui/icons-material/Login';
import { 
    Title,
    RefreshIcon,
    Playlist,
    FormAlert,
    NoticeBlock,
    SkeletonTable,
    Login,
    Button,
    TermsAndConditions,
    SampleReading,
}                                   from 'components';
import { withTranslation }          from 'components/hoc';
import {
    useNetwork,
    useUser,
    useAccountCredits
}                                   from 'contexts';
import ExamplePurchaseAlert         from './ExamplePurchaseAlert';
import ExampleCreditsRequiredAlert  from './ExampleCreditsRequiredAlert';
import { useExample }               from './ExampleContext';

const obj = {};

export const ExamplePlaylist = withTranslation( ({ t, crossSellQuantity = 0, playListProps = obj}) => {
    const {
        readingEnabled, 
        force,
        adminMode,
        queried,
        data,
        setData,
        files,
        working,
        query : handleQuery,
        natalArgs
    }                                   = useExample();
    const {isNetworkReady}              = useNetwork();
    const {isAdmin, isAuthenticated}    = useUser();
    const {
        balance,
        queried:accountBalanceQueried
    }                                   = useAccountCredits();
    const hasFunds                      = React.useMemo(() => balance >= data?.product?.price, [balance, data?.product?.price]);
    const title                         = React.useMemo(() => `Analysis${data?.name ? ` for ${data.name}` : ''}`, [data]);
    const success                       = React.useMemo(() => Boolean(data?.metadata?.result?.success || data?.success), [data?.metadata?.result?.success, data?.success]);
    const readingHasFiles               = React.useMemo(() => success && files, [success, files])
    
    // Free Duration
    const durationFree                  = React.useMemo(() => {

        let cnt = 0;
        
        const isFileFree = ({ everyone, public : isPublic, sample }) => (
            everyone || isPublic || (sample && cnt++ < data?.sampleCount)
        );

        return (files || [])
            .filter(({ permission }) => isFileFree(permission))
            .map(({ duration = 0 }) => duration)
            .reduce((acc, duration) => acc + duration, 0);

    },[files, data?.sampleCount]);

    // Piad and Admin Override Duration
    const [durationPaid, durationAdmin] = React.useMemo(() => {
        const isPaidOrFree = ({ everyone, public: isPublic, sample, purchased }) => everyone || isPublic || sample || purchased;
        const totalPaidDuration     = (files || []).reduce((acc, { duration = 0, permission }) => acc + isPaidOrFree(permission) * duration, 0);
        const totalAdminDuration    = (files || []).reduce((acc, { duration = 0 }) => acc + duration, 0);
        return [totalPaidDuration, totalAdminDuration];
      }, [files]);

    // Net Duration
    const duration          = React.useMemo(() => {
        const adminOverride = adminMode && isAuthenticated && isAdmin; // Admin Sees All
        return ( 
            adminOverride 
                ? durationAdmin 
                : (
                    data?.isPurchased 
                        ? durationPaid 
                        : durationFree
                )
        )
    }, [adminMode, isAuthenticated, isAdmin, durationAdmin, data?.isPurchased, durationPaid, durationFree]);

    const previewNatalArgs = React.useMemo(() => ({
        address:'<UNKNOWN_ADDRESS>',
        ...natalArgs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[JSON.stringify(natalArgs)])

    return (
        <Box>
            {
                readingEnabled && 
                <React.Fragment>
                    {
                        isAuthenticated && readingHasFiles &&
                        <Box mt={2} sx={{position:'relative'}}>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Title variant="h5" component="h3">
                                        {title}
                                    </Title>
                                    <TermsAndConditions title={null} showCheckbox={false} action={t('components.example.listeningToThisAnalysis')}/>
                                </Box>
                                {
                                    duration > 0 &&
                                    <Box sx={{my:'auto',ml:2,mr:1}}>
                                        {moment.duration(duration,'seconds').format(undefined)}
                                    </Box>
                                }
                            </Box>

                            {
                                isAdmin && force && !data.enabled &&
                                <Box mb={0.5}>
                                    <FormAlert severity="error">
                                        {t('components.example.playlistShownAdministration' )}
                                    </FormAlert>
                                </Box>
                            }

                            {
                                !adminMode && !data?.isPurchased && accountBalanceQueried && 
                                <Box my={1}>
                                    {
                                        hasFunds &&
                                        <ExamplePurchaseAlert 
                                            archiveId       = {data?._id}
                                            durationPaid    = {durationPaid}
                                            durationFree    = {durationFree}
                                            price           = {data?.product?.price}
                                            onQuery         = {handleQuery}
                                        />
                                    }

                                    {
                                        !hasFunds &&
                                        <ExampleCreditsRequiredAlert />
                                    }
                                </Box>
                            }
                            
                            <Playlist 
                                {...playListProps}
                                playlistId          = {data?.id} 
                                data                = {files} 
                                loading             = {working}
                                autoscroll          = {true}
                                allowComments       = {true}
                                crossSellQuantity   = {crossSellQuantity}
                                sticky              = {true}
                            />
                        </Box>
                    }

                    {
                        isAuthenticated && !readingHasFiles && 
                        <NoticeBlock title={title} mt={2}>
                            <Typography paragraph>
                                {t('components.example.errorRetrievingAnalysis')}
                            </Typography>
                            <Button 
                                disabled    = {working} 
                                startIcon   = {<RefreshIcon loading={working}/>} 
                                onClick     = {() => {
                                    setData(prev => ({...prev, files : undefined})); // kill files
                                    handleQuery(true)
                                }}
                            >
                                {t('common.refreshData')}
                            </Button>
                        </NoticeBlock>
                    }

                    {
                        !isAuthenticated &&
                        <NoticeBlock title={title} mt={2}>
                            <Login variant="contained" startIcon={<LoginIcon/>} size="large" popup={true}>
                                {t('common.loginToView')}
                            </Login>
                        </NoticeBlock>
                    }
                </React.Fragment>
            }

            {
                isNetworkReady && queried && 
                <>
                    {
                        !data?.enabled && !force && natalArgs &&
                        <NoticeBlock title={title}>
                            {
                                isAuthenticated
                                    ? (
                                        <Box>
                                            <Typography paragraph fontWeight={'bold'}>
                                                {t('components.example.analysisComingSoon')}
                                            </Typography>
                                            <Box sx={{m:-4,mt:2}}>
                                                <Typography gutterBottom variant="body2">
                                                    {t('components.example.analysisSample',{name:data?.name})}
                                                </Typography>
                                                <SampleReading 
                                                    showTitle               = {false}
                                                    showTitleResult         = {false}
                                                    showGraphics            = {false}
                                                    showSummaryTitle        = {false}
                                                    showSummary             = {false}
                                                    natalArgs               = {previewNatalArgs}
                                                    allowCrossSell          = {false}
                                                    scrollToResult          = {false}
                                                    AlertComponent          = {(props) => <FormAlert severity="info" {...props}/>}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                    : (
                                        <Login variant="contained" startIcon={<LoginIcon/>} size="large" popup={true}>
                                            {t('common.loginToPreview')}
                                        </Login>
                                    )
                            }
                        </NoticeBlock>
                    }
                </>
            }

            {
                (!isNetworkReady || !queried) && data?.enabled &&
                <Box sx={{position:'relative'}}>
                    <SkeletonTable/>
                </Box>
            }
            
        </Box>
    )
});

export default ExamplePlaylist;