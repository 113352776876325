/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- use Axios Loader
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import useAxios                         from './services/useAxios';

export const useLoader = (delay = 200) => {
    
    const {axios}               = useAxios();
    const [counter, setCounter] = React.useState(0);

    const increment = React.useCallback(() => {
        setTimeout(() => {
            setCounter(counter => counter + 1)
        },delay);
    }, [delay]); // add to counter

    const decrement = React.useCallback(() => {
        setTimeout(() => {
            setCounter(counter => counter - 1)
        },delay);
    }, [delay]); // remove from counter
    
    const interceptors = React.useMemo(() => ({
        request     : config    => { 
            increment(); 
            return config 
        },
        response    : response  => { 
            decrement(); 
            return response 
        },
        error       : error     => { 
            decrement();
            return Promise.reject(error) 
        },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [increment, decrement]); // create the interceptors
    
    React.useEffect(() => {

        // add request interceptors
        const requestInterceptor    = axios.interceptors.request.use(interceptors.request, interceptors.error);
        
        // add response interceptors
        const responseInterceptor   = axios.interceptors.response.use(interceptors.response, interceptors.error);
        
        // remove all intercepts when done
        return () => {
            axios.interceptors.request.eject(   requestInterceptor  );
            axios.interceptors.response.eject(  responseInterceptor  );
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interceptors]);

    // Boolean
    return [counter > 0, counter];
};

export default useLoader;