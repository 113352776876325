/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pages -- FAQ
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th January 2021

*******************************************************************************************/
import React                                from 'react';
import moment                               from 'moment';
import { 
    Box, CardMedia, 
    Grid, Typography,
    useMediaQuery
}                                           from '@mui/material';
import { Alert }                            from '@mui/material';
import ReactMarkdown                        from "react-markdown";
import { 
    Title,
    PageContainer,
    ProductCrossSell,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Seo,
    ContentfulRichText,
    Skeleton
}                                           from 'components';
import {
    useTranslation,
    useProduct,
    useContentful
}                                           from 'contexts';
import config       from '../../config';

const {company} = config;

const NumberedContent = ({title, children, variant, ...props}) => {
    return (
        <Box display='flex'>
            <Box flexShrink={1} sx={{textAlign:'right',minWidth:'30px',pr:2}}>
                {title && 
                    <Typography variant={variant} style={{textAlign:'inherit'}} sx={{...props.sx}}>
                        {title}
                    </Typography>
                }
            </Box>
            <Box flexGrow={1}>
                <Typography variant={variant} gutterBottom sx={{...props.sx}}>
                    {children}
                </Typography>
            </Box>
        </Box>
    )
}

export const FAQ = () => {

    const [t]                           = useTranslation();
    const {hasProducts}                 = useProduct();
    const {getFAQ}                      = useContentful();

    const [expanded,    setExpanded]    = React.useState(0);
    const [changed,     setChanged]     = React.useState(false);

    const [queried, setQueried]         = React.useState(undefined);
    const [loading, setLoading]         = React.useState(false);
    const [data,    setData]            = React.useState(undefined);

    const buildHeaderId = React.useCallback((ix) => `panel${ix+1}-header`, []);

    // Handle Panel Change
    const handleChange  = React.useCallback((panel) => async (event, isExpanded) => {
        if(isExpanded)
            setExpanded(undefined);
        setExpanded(isExpanded ? panel : undefined);
        setChanged(panel); 
    }, []);

    React.useEffect(() => {
        setLoading(true);
        getFAQ('faq')
            .then(items => setData(items[0]))
            .finally(() => {
                setLoading(false)
                setQueried(moment())
            })
    },[getFAQ])

    // Zoom into View
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {
        
        clearTimeout(timeoutRef.current);

        if(isNaN(expanded) || !changed)
            return;

        timeoutRef.current = setTimeout(() => {
            const el = document.getElementById(buildHeaderId(expanded));
            if(el) {
                el.style.scrollMargin = '10px';
                el.scrollIntoView({
                    behavior    : 'smooth', 
                    block       : 'start', 
                    inline      : 'start'
                });
            }
        }, 500);

        return () => {
            clearTimeout(timeoutRef.current);
        }

    }, [buildHeaderId, changed, expanded])

    const showSkeleton  = React.useMemo(() => (loading || !queried || !data), [data, loading, queried]); 
    const mdUp          = useMediaQuery(theme => theme.breakpoints.up('md'))

    return (
        <PageContainer>
            <Seo
                title           = { "FAQ"}
                description     = { `${company} Frequently Asked Questions` }
            />
            <Grid container>
                {
                    mdUp &&
                    <Grid item xs={12} md={4} align="center" sx={{position:'relative'}}>
                        <Box position="sticky" sx={{top:theme => theme.spacing(2)}}>
                            {
                                showSkeleton && 
                                <Box sx={{width:'100%',aspectRatio:'1'}}>
                                    <Skeleton height='100%' width="100%" />
                                </Box>
                            }
                            {   
                                !showSkeleton &&
                                <>
                                    <CardMedia
                                        component   = "img"
                                        src         = {`${data?.fields?.featuredImage?.fields?.file?.url}?w=500&fm=jpg&fl=progressive`}
                                        title       = {`FAQ`}
                                    />
                                    <Box sx={{mx:0.5}}>
                                        <Typography sx={{fontSize:'0.6rem',fontWeight:400,textAlign:'left'}}>
                                            {
                                                data?.fields?.featuredImage?.fields?.title
                                            }
                                        </Typography>
                                        <Typography sx={{fontSize:'0.6rem',textAlign:'justify'}}>
                                            {
                                                data?.fields?.featuredImage?.fields?.description
                                            }
                                        </Typography>
                                    </Box>
                                </> 
                            }
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} md={8} sx={{'& > * + *' : { mt : 2 }}}>

                    {
                        (loading || !queried) && 
                        <Box sx={{width:'100%',aspectRatio:'0.71'}}>
                            <Skeleton height='100%' width="100%" />
                        </Box>
                    }
                    {
                        !loading && queried && data && 
                        <>
                            <Title paragraph variant="h3" component="h1">
                                {data?.fields?.title || t('common.frequentlyAskedQuestions')}
                            </Title> 

                            <Box>
                                <ContentfulRichText content={data?.fields?.body} />
                            </Box>

                            {
                                data?.fields?.alert &&
                                <Box>
                                    <Alert severity="success">
                                        {data?.fields?.alert}
                                    </Alert>
                                </Box>
                            }   
                            
                            <Box>
                                {
                                    data?.fields?.faqs.map((faq,ix)=>(
                                        <Accordion key={ix} expanded={expanded === ix} onChange={handleChange(ix)}>
                                            <AccordionSummary id = {buildHeaderId(ix)} aria-controls = {`${buildHeaderId(ix)}-content`}>
                                                <NumberedContent title={`${ix+1}`} sx={{fontWeight:400}}>
                                                    {faq?.fields?.question}
                                                </NumberedContent>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <NumberedContent sx={{textAlign:'justify'}}>
                                                    {
                                                        faq?.sys?.contentType?.sys?.id === 'componentQuestionAndAnswerRich' &&
                                                        <ContentfulRichText content={faq?.fields?.answer} />
                                                    }
                                                    {
                                                        faq?.sys?.contentType?.sys?.id === 'componentQuestionAndAnswer' &&
                                                        <ReactMarkdown>
                                                            {faq?.fields?.answer}
                                                        </ReactMarkdown>
                                                    }
                                                </NumberedContent>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </Box>

                            {
                                data?.fields?.alert &&
                                <Box>
                                    <Alert severity="success">
                                        {data?.fields?.alert}
                                    </Alert>
                                </Box>
                            }  

                            {
                                hasProducts && 
                                <Box>
                                    <Title paragraph variant="h4" component="h2">
                                        Popular Products
                                    </Title> 
                                    <ProductCrossSell title={null} quantity={2} omitInCart = {false} />
                                </Box>
                            }
                        </>
                    }       
                </Grid>
            </Grid>
        </PageContainer>
    );
}

export default FAQ;