/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Update Natal Data
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd February 2022

*******************************************************************************************/
import React                            from 'react';
import { useNatalData, useUser }        from 'contexts';
import { AlertOnBehalfOfUser, Button }                       from 'components';
import { NatalDataV2 }                  from 'components/modals';
import { withTranslation }              from './hoc';
import { FORM_ERROR } from 'hooks';

const obj = {};
const noop = () => {};

const defaultFormDataFactory = (lat,lng) => ({
    _id             : undefined,
    lat             : lat, 
    lng             : lng, 
    address         : undefined, 
    gender          : undefined, 
    birthDateTime   : undefined, 
    unknownTime     : false,
    photo           : undefined,
    description     : undefined,
    roddenRating    : undefined
});

export const UpdateNatalData = withTranslation( 
    React.forwardRef(({
        t,
        userId,
        formData    : formDataIn    = obj,
        open        : openIn        = false, 
        component   : Component     = Button, 
        disabled                    = false, 
        onOpenChange                = noop,
        onChange : handleChange     = noop,
        natalDataFormProps          = obj,
        children,
        ...props
    }, ref) => {
        
        const {
            geolocation,
            userId : userIdAuth, 
            isAuthenticated, 
            isAdmin
        }                                   = useUser();
        const {
            updateNatalData,
            apiPostByIdForUserId
        }                                   = useNatalData();
        const [open, setOpen]               = React.useState(openIn);
        
        const handleOpen                    = React.useCallback( () => {
            setOpen(true);
            onOpenChange(true);
        }, [onOpenChange]);

        const handleClose                   = React.useCallback( () => {
            setOpen(false)
            onOpenChange(false);
        }, [onOpenChange]);

        React.useEffect(() => {
            setOpen(openIn);
        }, [openIn])

        const formData = React.useMemo(() => ({
            ...defaultFormDataFactory(geolocation?.lat,geolocation?.lng), 
            ...formDataIn 
        }), [formDataIn,geolocation?.lat, geolocation?.lng]);

        // Create
        const handleUpdate = React.useCallback( ({_id, id, lat, lng, address, birthDateTime, unknownTime, localTime, gender, isUser, photo, description, roddenRating, ...rest} = {}) => new Promise( (resolve,reject) => {
            const args = {_id : _id || id, id: _id || id, lat, lng, address, gender, birthDateTime, localTime, unknownTime, isUser, photo, description, roddenRating, ...rest};

            if(!_id && !id)
                return resolve({[FORM_ERROR]:"id is required"})

            if(isAuthenticated && isAdmin && userId && userId !== userIdAuth){
                apiPostByIdForUserId(userId, _id || id, args)
                    .then(handleClose)
                    .then(() => {
                        handleChange(args);
                        resolve({})
                    })
                    .catch(({errors = {}} = {}) => {
                        resolve(errors);
                    });
            }else{
                updateNatalData(args)
                    .then(handleClose)
                    .then(() => {
                        handleChange(args);
                        resolve({})
                    })
                    .catch(({errors = {}} = {}) => {
                        resolve(errors);
                    });
            }
        }), [apiPostByIdForUserId, updateNatalData, handleClose, handleChange, isAdmin, isAuthenticated, userId, userIdAuth])

        return (
            <>
                <Component
                    {...props}
                    ref         = {ref}
                    disabled    = {disabled || open}
                    onClick     = {handleOpen} 
                >
                    {children}
                </Component> 
                <NatalDataV2     
                    title               = { t('components.updateNatalData.updateNatalData') } 
                    formData            = {formData}
                    open                = {open}    
                    onSubmit            = {handleUpdate}   
                    onClose             = {handleClose}  
                    onCancel            = {handleClose}
                    natalDataFormProps  = {natalDataFormProps}
                >
                    <AlertOnBehalfOfUser userId={userId}/>
                </NatalDataV2>
            </>
        );
    })
);

export default UpdateNatalData;