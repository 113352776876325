
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                        from 'react';
import moment                       from 'moment';
import pick                         from 'lodash/pick';
import {
    useNetwork,
    useUser,
}                                   from 'contexts';
import {
    useCancelToken,
    useQueryHoroscopeData,
}                                   from 'hooks';     
import { 
    useQueryParam,
    BooleanParam,
    withDefault
}                                   from 'hooks/useQueryParams';
import { 
    useImageCDN,
    watermarkOption,
    useHoroscopeDescriptor,
    useClock
}                                   from 'hooks';
import config                       from 'config';import useExampleViewed from './useExampleViewed';
;

const GRAYSCALE     = Boolean(config?.archive?.grayscale);
const SAMPLE_COUNT  = 7;

// The Product Context 
const ExampleContext = React.createContext({});

const useNatalData = (data) => {
    
    const natalArgs = React.useMemo(() => (
        data 
            ? pick(data, ['lat','lng','birthDateTime','unknownTime','localTime','houseSystem','zodiac'])
            : undefined
    ), [data])

    return natalArgs;
}

// Example Provider
const ExampleProvider = ({
    slug, 
    force               = false, 
    adminMode           = false,
    children
}) => {

    const [debug]                                       = useQueryParam('debug',        withDefault(BooleanParam, false));
    const [omitFiles]                                   = useQueryParam('omitFiles',    withDefault(BooleanParam, false));
    const [omitMeta]                                    = useQueryParam('omitMeta',     withDefault(BooleanParam, true));

    const {cancelToken}                                 = useCancelToken();
    const {axios, isNetworkReady}                       = useNetwork();
    const {isAuthenticated}                             = useUser();
    const convert                                       = useImageCDN();
    const { now }                                       = useClock();

    const [status,          setStatus]                  = React.useState(undefined)
    const [working,         setWorking]                 = React.useState(false);
    const [queried,         setQueried]                 = React.useState(undefined);
    const [data,            setData]                    = React.useState(undefined);

    const natalArgs                                     = useNatalData(data);
    const photo                                         = React.useMemo(() => convert(data?.photo, { operation:'width', width:500, options:`grayscale:${Boolean(GRAYSCALE && !data?.isPurchased)}`}), [convert, data?.photo, data?.isPurchased]);
    const photoOG                                       = React.useMemo(() => (
        convert(
            data?.photo, 
            { 
                operation   :'cover:smart', 
                width       : 1200,
                height      : 628,
                options     : [
                    'grayscale:true',
                    watermarkOption(400,true)
                ].filter(Boolean).join(','), 
            }
        )
    ), [convert, data?.photo]);
    const readingEnabled                                = React.useMemo(() => isNetworkReady && queried && (data?.enabled || force), [data?.enabled, force, isNetworkReady, queried])

    // Clear Data
    const clear                                         = React.useCallback(() => { setQueried(undefined); setData(undefined); }, []);

    const crossSellQuantity                             = React.useMemo(() => 9, []);
    const crossSellRows                                 = React.useMemo(() => 3, []);

    // Horoscope Data
    const {
        horoscopeData   : dataHoroscope, 
        working         : workingDataHoroscope,
        queried         : queriedDataHoroscope
    }                                                   = useQueryHoroscopeData({...data});
    const {descriptor}                                  = useHoroscopeDescriptor(dataHoroscope, Boolean(!!data?.unknownTime));

    // Extract the Data
    // Only update files when id changes, this stops current playlist from being interrupted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const files                                         = React.useMemo(() => data?.files, [data?.id, data?.files]); 

    // View
    useExampleViewed(data?.id);
    
    // Query
    const query = React.useCallback((passive = false) => {
        if(isNetworkReady && slug){
            if(!passive)
                clear();
            setWorking(true);
            axios.get(`/api/examples/${slug}?auth=${isAuthenticated}&omitFiles=${omitFiles}&omitMeta=${omitMeta}&sampleCount=${SAMPLE_COUNT}&adminMode=${adminMode}`, {cancelToken})
                .then(({data,status}) => {
                    setData(data);
                    setStatus(status);
                })
                .catch(err => {
                    console.error(err);
                    setStatus(err.status);
                })
                .finally(() => {
                    setQueried(moment());
                    setWorking(false);
                })
        }else{
            setData(undefined);
        }
    },[isNetworkReady, slug, axios, isAuthenticated, omitFiles, omitMeta, adminMode, cancelToken, clear]);

    React.useEffect(query,[query]);

    const allowSharing = React.useMemo(() => {
        if (!data?.createdAt) 
            return false;
        return moment(data.createdAt).add(5, 'minutes').isBefore(now) ? true : false; // Disable if less than 5 minutes have passed
    }, [data?.createdAt, now]);

    // Context values
    const value = {
        slug,
        force,
        adminMode,
        debug,
        status,
        working,
        queried,
        data,
        setData,
        files,
        natalArgs,
        readingEnabled,
        photo,
        photoOG,
        query,
        clear,
        descriptor,
        dataHoroscope,
        workingDataHoroscope,
        queriedDataHoroscope,
        crossSellQuantity,
        crossSellRows,
        allowSharing
    };

    return (
        <ExampleContext.Provider value={value}>
            {children}
        </ExampleContext.Provider>
    )
}

// Example Consumer
const ExampleConsumer = ({children}) => {
    return (
        <ExampleContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ExampleConsumer must be used within ExampleProvider');
                }
                return children(context)
            }}
        </ExampleContext.Consumer>
    )
}

// useExample Hook
const useExample = () => {
    const context = React.useContext(ExampleContext);
    if(context === undefined)
        throw new Error('useExample must be used within ExampleProvider');
    return context;
}

export {
    ExampleProvider,
    ExampleConsumer,
    useExample
}