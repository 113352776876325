/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Dropdown
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th May 2021

*******************************************************************************************/
import React                            from 'react';
import MenuTitle                        from './MenuTitle';
import MenuPaper                        from './MenuPaper';
import { 
    styled,
    Box, 
    Popper as PopperMUI
}                                       from '@mui/material';

const Popper = styled(PopperMUI)({
    minWidth        : 200,
    zIndex          : 10000,
})

const noop = () => {};
const defaultPaperProps = {}

export const Dropdown = ({
    children, 
    open, 
    onClose : handleClose   = noop, 
    anchorEl                = null,
    duration                = undefined, 
    title                   = undefined, 
    paperProps              = defaultPaperProps,
    ...props
}) => {

    // AutoClose
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {

        clearTimeout(timeoutRef.current)
        
        if(isNaN(duration) || duration <= 0)
            return;
        
        if(!open)
            return;
        
        timeoutRef.current = setTimeout(handleClose, duration);
        
        return () => {
            clearTimeout(timeoutRef.current)
        }

    },[open, duration, handleClose])

    // Return the Component
    return (
        <Popper 
            transition 
            open            = {open} 
            disablePortal   = {true} 
            {...props}
        >
            <MenuPaper {...paperProps} sx={{minWidth : 300, maxWidth : 500}}>
                {title &&
                    <MenuTitle>
                        {title}
                    </MenuTitle>
                }
                <Box flexGrow={1}>
                    {children}
                </Box>
            </MenuPaper>
        </Popper>
    )
}

export default Dropdown