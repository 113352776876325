/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
TabPanel
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd January 2021

*******************************************************************************************/
import React                        from 'react';
import { v4 as uuidv4 }             from 'uuid';
import { useLocation, useHistory }  from 'react-router-dom';
import { 
    // IconButton,
    Tabs as TabsMUI, 
    Tab 
}                                   from '@mui/material';
import { RootContainer }            from 'components';
// import ChevronLeftIcon              from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon             from '@mui/icons-material/ChevronRight';

/*
const CustomScrollButton = (props) => {
    const { direction, onClick, ...other } = props;
    return (
        <IconButton onClick={onClick} size="small" variant="contained" color="primary" {...other}>
            {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
    );
}
*/

const useSearchParams = () => {
    const location          = useLocation();
    const history           = useHistory();
    const searchParams      = new URLSearchParams(location.search);
    const setSearchParams = (newParams, options = { replace: false }) => {
        const newSearch = new URLSearchParams(newParams).toString();
        const method = options.replace 
            ? history.replace 
            : history.push;
        method({ search: newSearch });
    };
    return [searchParams, setSearchParams];
};

// Custom comparison function that ignores React nodes
const isTabsDataEqual = (prev, next) => {
    if (prev === next) return true;

    if (!Array.isArray(prev) || !Array.isArray(next) || prev.length !== next.length) 
        return false;

    for (let i = 0; i < prev.length; i++) {

        const prevItem = prev[i];
        const nextItem = next[i];

        if (typeof prevItem === 'object' && typeof nextItem === 'object') {
            if (
                prevItem?.label      !== nextItem?.label      ||
                prevItem?.hidden     !== nextItem?.hidden     ||
                prevItem?.disabled   !== nextItem?.disabled   ||
                prevItem?.value      !== nextItem?.value
            ) {
                return false;
            }
        } else if (prevItem !== nextItem) {
            return false;
        }
    }

    return true;
};

const useTabsDataCompareAndMemoise = (value) => {
    const ref = React.useRef();
    const [memoizedValue, setMemoizedValue] = React.useState(value);

    React.useEffect(() => {
        if (!isTabsDataEqual(ref.current, value)) {
            ref.current = value;
            setMemoizedValue(value); // Update memoized value only if it changes
        }
    }, [value]);

    return memoizedValue;
};

const useTabsData = (data) => {

    // Normalize data
    const normalizedData = useTabsDataCompareAndMemoise(

        (data || []).filter(Boolean).map((item, index) =>
            typeof item === 'string'
                ? { 
                    label       : item, 
                    hidden      : false, 
                    disabled    : false,
                    value       : index,
                } : {
                    ...item,
                    label       : item?.label       ?? `Tab${index + 1}`,
                    hidden      : item?.hidden      ?? false,
                    disabled    : item?.disabled    ?? false,
                    value       : item?.value       ?? index
                }
        )
    );

    return normalizedData;
}

// noop
const noop = () => {};

// Create a context for tabs
const TabContext = React.createContext();

// hook
const useTab = () => {
    const context = React.useContext(TabContext);
    if (context === undefined) {
        throw new Error('useTab must be used within a TabProvider');
    }
    return context;
};

const DEFAULT_TAB_QUERY_PARAM_NAME = 'tab';

// TabProvider component to provide tab state and functionality
export const TabProvider = React.forwardRef(({ 
    children, 
    data            : rawData, 
    onChange        : handleTabChange   = noop, 
    syncWithQuery   = false,
    queryParamName  = DEFAULT_TAB_QUERY_PARAM_NAME
}, ref) => {

    if(!queryParamName)
        throw new Error('queryParamName cannot be empty')

    const providerId                = uuidv4(); 
    const data                      = useTabsData(rawData);
    const [tabValue, setTabValue]   = React.useState(null);
    const [isSyncing, setIsSyncing] = React.useState(Boolean(syncWithQuery));  // Track sync state

    // Sync with query parameters if enabled
    const [
        searchParams, 
        setSearchParams
    ]                               = useSearchParams();

    const updateTabSearchParam      = React.useCallback((tabValue) => {
        if(!syncWithQuery) 
            return;
        const newSearchParams = new URLSearchParams(searchParams);          // Copy existing params
        if (tabValue === undefined || tabValue === null || tabValue === '') {
            newSearchParams.delete(queryParamName);                         // Remove the tab parameter if invalid
        } else {
            newSearchParams.set(queryParamName, tabValue);                  // Update the tab param
        }
        setSearchParams(newSearchParams.toString(), { replace: true});      // Set the new search params as a string
    }, [queryParamName, searchParams, setSearchParams, syncWithQuery]);

    // Reset the active tab and sync the query parameter
    const resetActiveTab            = React.useCallback(() => {
        const finalValue = data.find(tab => !tab.hidden && !tab.disabled)?.value || 0;
        updateTabSearchParam(finalValue);
        setTabValue(finalValue);
    }, [data, updateTabSearchParam]);

    // Sync query param with tabValue (only on initial load)
    React.useEffect(() => {
        if (!syncWithQuery) return;  // Short circuit if syncWithQuery is false
    
        const queryValue = searchParams.get(queryParamName);
    
        // Normalize queryValue to handle both strings and numbers
        const normalizedQueryValue = isNaN(queryValue) ? queryValue : Number(queryValue);
    
        // Find a matching tab value in the data
        const matchedTab = data.find(tab => !tab.hidden && tab.value === normalizedQueryValue);
    
        if (matchedTab) {
            // If matched, set the tabValue from the query
            setTabValue(matchedTab.value);
        } else {
            // If no match, set tabValue to null to trigger reset in the next effect
            setTabValue(null);
        }
        setIsSyncing(false); // Mark syncing as done
    }, [syncWithQuery, searchParams, data, queryParamName]);

    // Ensure tabValue is set to a valid tab when syncing finishes
    React.useEffect(() => {
        if (syncWithQuery && isSyncing) return; // Don't execute fallback while syncing

        if (tabValue === null) {
            resetActiveTab();  // Set to first available tab if no valid tab value is set
        }
    }, [tabValue, resetActiveTab, isSyncing, syncWithQuery]);

    const setTabValueExternal = React.useCallback((newValue) => {
        const normalizedNewValue = (
            isNaN(newValue) 
                ? newValue 
                : Number(newValue)
        );
        const isValidValue = data.some(
            item => item.hidden === false && item.value === normalizedNewValue
        );
        const fallbackValue = data.find(tab => !tab.hidden && !tab.disabled)?.value || 0;
        const finalValue = (
            isValidValue 
                ? normalizedNewValue 
                : fallbackValue
        );
        updateTabSearchParam(finalValue);
        setTabValue(finalValue);
    }, [data, updateTabSearchParam]);

    const handleChange = React.useCallback((event, newValue) => {
        setTabValueExternal(newValue);
        handleTabChange(event, newValue);
    }, [setTabValueExternal, handleTabChange]);

    const tabProps = React.useCallback(({ index, ...props }) => ({
        ...props,
        id: `tab-${providerId}-${index}`,
        'aria-controls': `tabpanel-${providerId}-${index}`,
    }), [providerId]);

    const tabPanelProps = React.useCallback(({ index, ...props }) => ({
        ...props,
        role: 'tabpanel',
        id: `tabpanel-${providerId}-${index}`,
        'aria-labelledby': `tab-${providerId}-${index}`,
    }), [providerId]);

    React.useImperativeHandle(ref, () => ({
        setTabValue: setTabValueExternal,
        resetActiveTab
    }));

    return (
        <TabContext.Provider
            value = {{
                data,
                providerId,
                tabValue,
                setTabValue: setTabValueExternal,
                resetActiveTab,
                handleChange,
                tabProps,
                tabPanelProps,
            }}
        >
            {children}
        </TabContext.Provider>
    );
});

// Tabs component to render the tabs
export const Tabs = React.forwardRef(({
    disabled = false, 
    ...props 
}, ref) => {
    const { tabValue, handleChange, data, tabProps } = useTab();
    return (
        <TabsMUI 
            {...props} 
            ref                     = {ref}
            disabled                = {disabled} 
            value                   = {tabValue} 
            onChange                = {handleChange} 
            variant                 = "scrollable" 
            scrollButtons           = "auto" 
            indicatorColor          = "primary"
            // ScrollButtonComponent   = {CustomScrollButton}
            allowScrollButtonsMobile
        >
            {
                data.map(({ label, disabled : tabDisabled = false, hidden = false, ...props }, index) => (
                    !hidden &&
                    <Tab 
                        { ...tabProps({index, ...props}) } 
                        key         = {index} 
                        label       = {label}
                        disabled    = {disabled || tabDisabled} 
                    />
                ))
            }
        </TabsMUI>
    );
});

// Custom TabPanel component
export const TabPanel = ({ children, index, hidden = false, unmountOnExit = false, ...props }) => {
    const { tabValue, tabPanelProps } = useTab();
    if (unmountOnExit && tabValue !== index)
        return null;
    return (
        <RootContainer
            hidden  = {hidden || tabValue !== index}
            { ...tabPanelProps({index, ...props}) }
            sx = {{
                height  : 'fit-content',
                mt      : 2,
                ...props?.sx
            }}
        >
            {children}
        </RootContainer>
    );
};

export default TabPanel;



/*
import React                    from 'react';
import { Tabs, Tab}             from '@mui/material';
import { RootContainer }        from 'components';

const tabProps = (index) => {
    return {
        id                  : `tab-${index}`,
        'aria-controls'     : `tabpanel-${index}`
    };
}

const tabPanelProps = (index) => {
    return {
        role                : 'tabpanel',
        id                  : `tabpanel-${index}`,
        'aria-labelledby'   : `tab-${index}`
    };
}

const TabPanel = ({children, unmountOnExit=false, value, index = 0, ...props}) => {
    if(unmountOnExit && value !== index) 
        return null;
    return (
        <RootContainer
            hidden          = { value !== index }
            {...props}
            sx              = {{
                height : '100%',
                ...props.sx
            }}
            {...tabPanelProps(index)}
        >
            {children}
        </RootContainer>
    );
};

export {
    TabPanel,
    Tab,
    Tabs,
    tabProps,
    tabPanelProps
}

export default TabPanel;
*/