
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateRange Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th January 2023

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { useStateEphemeral }            from 'hooks';
import { 
    useQueryParam, 
    withDefault,
    DateTimeParam
}                                       from 'hooks/useQueryParams';

// The Product Context 
const DateRangeContext = React.createContext({});

// DateRange Provider
const DateRangeProvider = ({children, initialRange = 'month'}) => {

    // DATE RANGE VARIABLES AND METHODS
    const [start,           setStart]           = useQueryParam('start',    withDefault(DateTimeParam, moment().startOf( initialRange ).toDate()));
    const [finish,          setFinish]          = useQueryParam('finish',   withDefault(DateTimeParam, moment().endOf(   initialRange ).toDate()));
    const [value,           setValue]           = React.useState({ start : moment(start), finish  : moment(finish) });
    const [successMessage,  setSuccessMessage]  = useStateEphemeral(undefined);
    const [errorMessage,    setErrorMessage]    = useStateEphemeral(undefined);

    const [open,   setOpenDateRange]            = React.useState(false);
    const handleOpen                            = React.useCallback(() => setOpenDateRange(true), []);
    const handleClose                           = React.useCallback(() => setOpenDateRange(false), []);

    React.useEffect(() => {
        setStart(   value?.start    ? value?.start.toDate()   : undefined );
        setFinish(  value?.finish   ? value?.finish.toDate()  : undefined );
    }, [value?.start,value?.finish, setFinish, setStart])

    const timeoutRef                            = React.useRef(null);
    const handleChange                          = React.useCallback(({start,finish}) => {
        clearTimeout(timeoutRef.current)
        if(!moment.isMoment(start) || !moment.isMoment(finish)){
            setErrorMessage('dateRange change object must contain start and finish entries, which must be a moment');
        }
        if(!moment(start).isSame(value.start) || !moment(finish).isSame(value.finish)){
            setSuccessMessage('Dates Changed');
        }
        setValue({ start, finish});
        timeoutRef.current = setTimeout(handleClose,0);
    },[value.start, value.finish, handleClose, setErrorMessage, setSuccessMessage]);

    const data = {
        handleChange,
        handleOpen,
        handleClose,
        value,
        open,
        successMessage,
        errorMessage
    }

    return (
        <DateRangeContext.Provider value={data}>
            {children}
        </DateRangeContext.Provider>
    )
}

// DateRange Consumer
const DateRangeConsumer = ({children}) => {
    return (
        <DateRangeContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('DateRangeConsumer must be used within DateRangeProvider');
                }
                return children(context)
            }}
        </DateRangeContext.Consumer>
    )
}

// useDateRange Hook
const useDateRange = () => {
    const context = React.useContext(DateRangeContext);
    if(context === undefined)
        throw new Error('useDateRange must be used within DateRangeProvider');
    return context;
}

export {
    DateRangeProvider,
    DateRangeConsumer,
    useDateRange
}