/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Payment Method Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th May 2021

*******************************************************************************************/
import React                    from 'react';
import {clone}                  from 'lodash';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {useUser}                from './UserContext';
import {
    useStateEphemeral,
    useCancelToken
}                               from '../hooks';
import {debounce}               from '../functions';

// The Context Object
export const PaymentMethodContext = React.createContext(undefined);

const VALID_PAYMENT_METHODS = ['STRIPE'];

const BASE_API_URL = '/api/user/payment/method'

// Voltage Provider
export const PaymentMethodProvider = ({children}) => {

    const {isAuthenticated, ready}                          = useUser();
    const {isNetworkReady, axios, socketUsers : socket}     = useNetwork();
    const {cancelToken }                                    = useCancelToken();

    const [isRetrieved,     setRetrieved]                   = React.useState(false);

    // Messages
    const [messageSuccess,  setMessageSuccess]              = useStateEphemeral(undefined);
    const [messageWarning]                                  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]                = useStateEphemeral(undefined);

    // Default Method
    const [paymentMethodDefault, setPaymentMethodDefault]   = React.useState(undefined);
    const [queried, setQueried]                             = React.useState(undefined);

    // The Collection of Methods
    const [paymentMethods, setPaymentMethods]               = React.useState([]);
    const [loading, setLoading]                             = React.useState(false);
    const [refreshing, setRefreshing]                       = React.useState(false);

    // Clear Methods
    const clear = () => {
        setPaymentMethods([]);
        setQueried(undefined);
    }

    // Get collection
    const getPaymentMethods = React.useCallback( (passive = false) => new Promise( (resolve,reject) => {
        if(!passive) setLoading(true)
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Queried payment methods");
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message)
                reject(err);
            })
            .finally(()=>{
                setRetrieved(true);
                setLoading(false)
                setQueried(moment())
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    // Perform refresh
    const timeoutRef = React.useRef(null);
    const refresh = React.useCallback( (clearData) => {
        
        clearTimeout(timeoutRef.current)

        if(!isAuthenticated || !ready || !isNetworkReady){
            clear();
            return;
        }

        if(clearData) 
            clear();

        setRefreshing(true);
        getPaymentMethods(true)
            .then(setPaymentMethods)
            .catch(clear)
            .finally(() => {
                timeoutRef.current = setTimeout(() => {
                    setRefreshing(false);
                },500)
            })
    },[getPaymentMethods, isAuthenticated, isNetworkReady, ready])

    // Create a new method
    const createMethod = ({type, paymentMethod, isDefault}) => new Promise((resolve,reject) => {
        type = typeof type === 'string' ? type.toUpperCase() : type;
        if(typeof type !== 'string' || !VALID_PAYMENT_METHODS.includes(type))
            return reject(new Error('invalid type'));
        if(typeof paymentMethod !== 'object')
            return reject(new Error('paymentMethod must be an object'));

        axios.post(BASE_API_URL, { type, data: paymentMethod, isDefault: Boolean(isDefault)}, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Payment method created");
                refresh();
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err)
            })
    })

    // Delete an Existing Method
    const deleteMethod = React.useCallback( ({id}) => new Promise((resolve,reject) => {
        let backup = clone(paymentMethods);
        setPaymentMethods(prev => prev.filter(method => method._id !== id));
        setMessageSuccess('Deleting payment method');
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Payment method deleted");
                resolve(data);
            })
            .catch(err => {
                setPaymentMethods(backup);
                setMessageError(err?.message);
                reject(err)
            })
    }), [axios, cancelToken, paymentMethods, setMessageError, setMessageSuccess])

    // Update a method, set it as default or not
    const updateDefault = React.useCallback( ({id,isDefault}) => new Promise((resolve,reject) => {
        let backup = clone(paymentMethods);
        setPaymentMethods(prev => prev.reduce((acc,cur) => [...acc, {...cur,isDefault : cur._id === id ? isDefault : false}], []))
        setMessageSuccess('Updating default method');
        axios.post(`${BASE_API_URL}/default/${id}`, {isDefault : Boolean(isDefault)}, {cancelToken})
            .then(({data, message})=>{
                if(isDefault)
                    setMessageSuccess('Default method set');
                resolve(data);
            })
            .catch(err => {
                setPaymentMethods(backup);
                setMessageError(err?.message)
                reject(err)
            })
    }), [axios, cancelToken, paymentMethods, setMessageError, setMessageSuccess])

    // Update default when payment methods change
    React.useEffect(()=>{
        try{
            const filtered = paymentMethods.length === 1 ? paymentMethods : (paymentMethods || []).filter(x => x.isDefault)
            setPaymentMethodDefault(filtered.length ? filtered[0] : undefined);
        }catch(err){
            setPaymentMethodDefault(undefined);
        }
    },[paymentMethods])

    // Retrieve from Server
    React.useEffect(refresh,[refresh]);

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(socket){
            socket.on('refresh_payment_methods', refresh)
            return () => {
                socket.off('refresh_payment_methods', refresh);
            }
        }
    },[refresh, setMessageSuccess, socket])

    // Is there a default
    const hasDefault = Boolean(paymentMethodDefault)

    const quantity = (paymentMethods || []).length;

    // Context values
    const value = {
        messageSuccess,
        messageWarning,
        messageError,
        paymentMethods,
        paymentMethodDefault,
        hasDefault,
        quantity,
        loading,
        refreshing,
        refresh : debounce(refresh),
        createMethod,
        deleteMethod,
        updateDefault,
        queried,
        isRetrieved
    };

    return (
        <PaymentMethodContext.Provider value={value}>
            {children}
        </PaymentMethodContext.Provider>
    )
}

export const PaymentMethodConsumer =  ({children}) => {
    return (
        <PaymentMethodContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('PaymentMethodConsumer must be used within PaymentMethodProvider');
                }
                return children(context)
            }}
        </PaymentMethodContext.Consumer>
    )
}

// useVoltage Hook
export const usePaymentMethod = () => {
    const context = React.useContext(PaymentMethodContext);
    if(context === undefined)
        throw new Error('usePaymentMethod must be used within PaymentMethodProvider');
    return context;
}