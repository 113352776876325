/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use State Debounced Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th November 2022

*******************************************************************************************/
import React from 'react';

export const useStateDebounced = (initialValue, timeout = 500) => {

    if(isNaN(timeout) || !(timeout > 0)) // handle null 
        throw new Error("timeout must be positive numeric")

    const [debouncedValue,  setDebouncedValue]      = React.useState(initialValue);
    const [value,           setValue]               = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    // Update debounced value after elapsed time, 
    // after value or timeout changes
    const timeoutRef = React.useRef(null);
    React.useEffect(() => {
        clearTimeout(timeoutRef.current);
        if(timeout > 0){
            timeoutRef.current = setTimeout(() => {
                setDebouncedValue(value);
            }, timeout)
            return () => {
                clearTimeout(timeoutRef.current);
            }
        }
    },[timeout, value])

    return [debouncedValue,  setValue];
};

export default useStateDebounced;