/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CountDown or Timestamp
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th February 2022

*******************************************************************************************/

import React        from 'react';
import moment       from 'moment';
import {Box}        from '@mui/material';
import {useLocale}  from 'contexts';
import {useClock}   from 'hooks';
import {CountDown}  from 'components';

// Pad Function
const pad       = x => x.toString().padStart(2,'0');

// Default Formatter Function
const defaultFormatter = ({prefix, days, hours, minutes, seconds}) => (
    `${prefix}${  [days,hours,minutes,seconds].map(pad).join(':') }`
);

const obj       = {};

export const CountDownOrTimestamp = ({now : nowIn = undefined, to : toIn = undefined, formatter = defaultFormatter, countDownProps=obj, ...props}) => {
    const {now: nowLoc}         = useClock();
    const { formatDateTime }    = useLocale();
    const to                    = React.useMemo(() => moment.isMoment(toIn) ? toIn : moment(toIn), [toIn]);
    const now                   = React.useMemo(() => moment.isMoment(nowIn) ? nowIn : nowLoc, [nowIn, nowLoc]);
    
    // No datetime provided
    if(!toIn)
        return null;

    return (
        <Box {...props}>
            {
                now.isSameOrBefore(to)
                    ? <CountDown allowNegative={true} {...countDownProps} to={to} formatter = {formatter}/>
                    : formatDateTime(to)
            }
        </Box>
    )
}

export default CountDownOrTimestamp;