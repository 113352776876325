/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
isDayTime Hook
********************************************************************************************

// TODO Dynamically calculate sunrise/sunset times from timezone or geolocation

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th June 2021

*******************************************************************************************/

import React                        from 'react';
import {isNil}                      from 'lodash';
import moment                       from 'moment';
// import { getSunrise, getSunset }    from 'sunrise-sunset-js';
import { useCookies }               from 'react-cookie';
// import {useCurrentLocation}         from '../contexts';

const setIntervalAndExecute = (fn, t) => {
    fn();                       // Run Immediately
    return setInterval(fn, t);  // Allways start the interval
}

export const useDayTime = ({
    period = 30000
} = {}) => {
    const [cookies, setCookie]          = useCookies(['isDayTime']);
    const [isDayTime, setIsDayTime]     = React.useState(cookies.isDayTime);
    // const {latitude,longitude,valid}    = useCurrentLocation();

    React.useEffect(()=>{
        setCookie('isDayTime',isDayTime);
    },[isDayTime, setCookie])

    // React.useEffect(light,[]);
    const intervalRef = React.useRef(null);
    React.useEffect(() => {

        clearInterval(intervalRef.current);
            
        // Function to check whether day change
        const checkDayChange = () => {

            let now = new Date();
            
            // Calculate Sunrise/Sunset
            // let sr = undefined, ss = undefined;
            /*
            if(valid){
                sr = moment( getSunrise(latitude,  longitude, now) ).subtract(1,'days');
                ss = moment( getSunset( latitude,  longitude, now) );
            }
            */

            let sr = moment('7:00am','h:mma'),
                ss = moment('5:00pm','h:mma');

            if(moment.isMoment(sr) && moment.isMoment(ss)){

                //const   sunrise     = moment.isMoment(sr) ? sr : moment().local().startOf('day').add(8, 'hours'),     // Guesstimate of sunrise, today
                //        sunset      = moment.isMoment(ss) ? ss : moment().local().startOf('day').add(20,'hours');     // Guesstimate of sunset, today
                
                // Is now
                const   isDay       = moment(now).isAfter(sr) && moment(now).isBefore(ss);

                // Update the state
                setIsDayTime(isDay);
            }
        }

        // Minimum check Frequency
        let minimum         = 1000;

        // Determine the period from the 
        let delay           = Math.max(Number.isFinite(period) ? period : minimum, minimum);

        // Check on interval
        intervalRef.current    = setIntervalAndExecute(checkDayChange, delay);

        // Destroy interval
        return () => {
            clearInterval(intervalRef.current);
        }

    },[period]);

    return {
        isDayTime,
        isNightTime : isNil(isDayTime) ? undefined : !isDayTime
    }
}

export default useDayTime;