/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public Resources Advertisement
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd October 2022

*******************************************************************************************/
import React                                from 'react';
import moment                               from 'moment';
import { useTheme, Box }                    from '@mui/material';
import { 
    ImageLinkWithOverlay,
    Title, 
    RefreshIcon,
    DebouncedButton,
    LikeDisplay,
    Skeleton
}                                           from 'components';
import { usePublicResources }               from 'hooks';
import { BooksLocation }                    from 'router';

export const PublicBooksAdvertisement = ({title="Books", auto = true, excludeId = undefined, quantity = 5, ...props}) => {
    
    const theme                     = useTheme();
    const {getRandomItems, ready}   = usePublicResources();
    const [items, setItems]         = React.useState(undefined);
    const [working, setWorking]     = React.useState(false);
    const [queried, setQueried]     = React.useState(undefined);
    
    const refresh = React.useCallback( () => {
        setWorking(true);
        setItems(undefined);
        getRandomItems(quantity + (excludeId ? 1 : 0))
            .then(({items}) => setItems(
                excludeId 
                    ? items.filter(x => x._id !== excludeId).slice(0,quantity)
                    : items
            ))
            .catch(() => setItems(undefined))
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    }, [excludeId, getRandomItems, quantity]);

    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        clearInterval(intervalRef.current);
        if(!ready){
            setItems(undefined);
            return;
        }
        refresh();
        if(!auto)
            return;
        intervalRef.current = setInterval(refresh, 60 * 1000);
        return () => {
            setItems(undefined);
            clearInterval(intervalRef.current);
        }
    }, [auto, getRandomItems, quantity, ready, refresh])

    return (
        <Box sx={{width:'100%'}}>
            {
                title &&
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Title variant="h5" component="h3">
                            {title}
                        </Title>
                    </Box>
                    <Box>
                        <DebouncedButton color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'} disabled={working} onClick={refresh} startIcon={working ? <RefreshIcon loading={true}/> : null}>
                            Refresh
                        </DebouncedButton>
                    </Box>
                </Box>
            }
            <Box display={"flex"}>
                {
                    Boolean(!working && queried && items?.length) &&
                    items.map((item,ix) => (
                        <Box flexBasis={`${(100 / quantity).toFixed(1)}%`} key={ix} sx={{position:'relative'}}>
                            <ImageLinkWithOverlay 
                                name        = "Learn More"
                                to          = {BooksLocation.toUrl({id : item?._id})}
                                photo       = {item?.data?.main_image}
                                alt         = {item?.data?.title}
                            />
                            {
                                item?.likeGroup &&
                                <LikeDisplay likes={item?.likeGroup?.likeCount} dislikes={item?.likeGroup?.dislikeCount} />
                            }
                        </Box>
                    ))
                }
                {
                    Boolean(working || !queried || !items?.length) &&
                    Array(quantity).fill(undefined).map((_,ix) => (
                        <Box flexBasis={`${(100 / quantity).toFixed(1)}%`} key={ix} sx={{position:'relative', aspectRatio:'0.71', mx:0.1}}>
                            <Skeleton />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

export default PublicBooksAdvertisement