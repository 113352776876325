/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Confirmation Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th June 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    styled,
    Box,
    Button as ButtonMUI
}                               from '@mui/material';
import { useTranslation }       from 'contexts';
import { withDisabledOffline }  from './hoc';

const ButtonBase = styled(ButtonMUI, {
    shouldForwardProp : prop => prop !== 'clicked'
})(({theme, clicked = false}) => ({
    ...(clicked && {
        color       : [theme.palette.error.contrastText,'!important'],
        background  : theme.palette.error.main,
        "&:hover"   : {
            background  : theme.palette.error.dark,
        }
    }),
    ...(!clicked && {

    }),
}));

const Progress = styled("span")(({theme, width}) => ({
    top         : 1,
    left        : 1,
    bottom      : 1,
    zIndex      : 1,
    overflow    : 'hidden',
    position    : 'absolute',
    background  : theme.palette.text.secondary,
    opacity     : 0.4,
    width       : (
        typeof width === 'number' && !isNaN(width)
            ? `${width}%` 
            : width
    )
})) 

const Button    = withDisabledOffline(ButtonBase);
const noop      = () => {};
const obj       = {};

export const ConfirmationButton = ({
    children, 
    variant                     = "text",
    confirmationText            = undefined,
    onClick                     = noop,
    onConfirmationRequired  : handleConfirmationRequired = noop,
    duration                    = 5000,
    updateFrequency             = 10,
    decimals                    = 0,
    requireConfirmation         = true,
    ProgressProps               = obj,
    ...props
}) => {
    
    const [t]                               = useTranslation();
    const [elapsed,     setElapsed]         = React.useState(0);
    const [clicked,     setClicked]         = React.useState(false);
    const [clickedTime, setClickedTime]     = React.useState(undefined);

    // The remainiing seconds
    const remainingSecs = React.useMemo(() => (
        Math.max((duration - elapsed) / 1000.0, 0)
    ), [duration, elapsed]);

    // When clicked state changes, handle confirmation required
    React.useEffect(() => {
        handleConfirmationRequired(clicked);
        return () => {
            handleConfirmationRequired(false);
        }
    },[clicked, handleConfirmationRequired])

    // Handle Excape Key Press, Cancel Confirmation Timer.
    React.useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                setClicked(false);
            }
        }
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        }
    },[])

    // On clicked State Change
    React.useEffect(() => {
        if(clicked) {
            setClickedTime(new Date().getTime());
        }else{
            setElapsed(0);
            setClickedTime(undefined);
        }
    },[clicked])

    // If clicked changes to true, countdown
    const intervalRef = React.useRef(null);
    React.useEffect(() => {

        clearInterval(intervalRef.current);

        if(isNaN(duration) || duration <= 0)
            return;

        if(!clicked || !clickedTime)
            return;

        intervalRef.current  = setInterval(() => {
            const done  = new Date().getTime() - clickedTime;
            setElapsed(done);
            if(done >= duration){
                setClicked(false);
                clearInterval(intervalRef.current);
            }
        }, updateFrequency);

        return ()=>{
            clearInterval(intervalRef.current);
        }
        
    },[clicked, updateFrequency, duration, clickedTime])

    // Show span or not
    const showSpan = React.useMemo(() => (
        !isNaN(duration) && duration > 0
    ), [duration]);

    // Two-step click process
    const handleClick = (event) => {
        if (requireConfirmation) {
            if (!clicked) {
                // First click, set clicked to true and return early
                setClicked(true);
                return;
            }
            // Second click, reset clicked state
            setClicked(false);
        }
        // Proceed with onClick
        onClick(event);
    };

    // Render
    return (
        <Button 
            id          = "confirmationButton"
            variant     = {clicked && variant === 'text' ? 'contained' : variant}
            clicked     = {clicked}
            onClick     = {handleClick}
            color       = "error"
            {...props}
        >
            <Box sx={{pr:0.5}}>
                {
                    clicked 
                        ? `${confirmationText || t("common.clickToConfirm")} ${(remainingSecs).toFixed(decimals)}` 
                        : children
                }
            </Box>
            {
                showSpan && 
                <Progress width={100 * elapsed/duration} {...ProgressProps} />
            }
        </Button>
    )
}

export default ConfirmationButton;