
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- Use JWT Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st December 2020

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import jwt_decode               from "jwt-decode";

import { useAuth0 }             from '@auth0/auth0-react';
import { useAxios }             from './useAxios';
import { useInternet }          from './useInternet';
import { useAlert }             from 'contexts';

const CHECK_INTERVAL = 5000;

export const useJWT = () => {

    const { isOnline }                                          = useInternet(); 
    const { token, setToken, revokeToken}                       = useAxios();
    const { isAuthenticated, logout, getAccessTokenSilently}    = useAuth0();
    const { alert }                                             = useAlert();
    const [ expires, setExpires ]                               = React.useState(undefined);
    const ready                                                 = React.useMemo(() => Boolean(token), [token]);

    // Listen to isAuthenticated Change
    React.useEffect(()=>{
        if(isAuthenticated && isOnline){
            getAccessTokenSilently()
                .then(token => {
                    alert("User authenticated",'success');
                    setToken(token)
                })
                .catch(err => {
                    alert(err.message || "Token Revoked", "error");
                    revokeToken();
                })
        }else{
            revokeToken();
        }
    },[alert, getAccessTokenSilently, isAuthenticated, isOnline, revokeToken, setToken]);

    // Set Expires on Token Change
    React.useEffect(() => {
        if(!token){
            setExpires( undefined );
            return;
        }
        const decoded = jwt_decode(token);
        const exp     = moment.unix(decoded.exp).utc();
        setExpires( exp );
    },[token])

    // Check if the token needs revoking / logging out
    const intervalRef = React.useRef(null);
    React.useEffect(() => {
        
        clearInterval(intervalRef.current);
        if(!expires || !moment.isMoment(expires))
            return;

        intervalRef.current = setInterval(() => {
            const threshold = moment().utc().subtract(30,'minutes');
            if(expires < threshold){
                alert("Token Expired", "error");
                revokeToken();
                logout();
            }
        }, CHECK_INTERVAL )

        return () => {
            clearInterval(intervalRef.current);
        }
    },[expires, revokeToken, logout, alert])

    // Return the states
    return {
        token,
        expires,
        ready,
    }
}

export default useJWT;